<div class="container">
    <h2>Administrierung</h2>
    <div class="nav-elements">
        <div class="card" routerLink="/admin/logins">
            <h3><i class="fa-solid fa-user"></i>Logins</h3>
            <p class="helper">Verwaltung von App-Nutzern.</p>
            <div class="link">
                <i class="fa-solid fa-arrow-up-right-from-square"></i>
            </div>
        </div>
        <div class="card" routerLink="/admin/instruments">
            <h3><i class="fa-solid fa-drum"></i>Instrumente</h3>
            <p class="helper">Verwaltung von Instrumenten Typen.</p>
            <div class="link">
                <i class="fa-solid fa-arrow-up-right-from-square"></i>
            </div>
        </div>
        <div class="card" routerLink="/admin/tasks">
            <h3><i class="fa-solid fa-list-check"></i>Übungen</h3>
            <p class="helper">Verwaltung von allen Übungen</p>
            <div class="link">
                <i class="fa-solid fa-arrow-up-right-from-square"></i>
            </div>
        </div>
        <div class="card" routerLink="/admin/students">
            <h3><i class="fa-solid fa-graduation-cap"></i>Jungbläser</h3>
            <p class="helper">Verwaltung von Jungbläsern.</p>
            <div class="link">
                <i class="fa-solid fa-arrow-up-right-from-square"></i>
            </div>
        </div>
        <div class="card" routerLink="/admin/instructors">
            <h3><i class="fa-solid fa-chalkboard-user"></i>Jungbläser Anleiter</h3>
            <p class="helper">Verwaltung von Jungbläser Anleitern.</p>
            <div class="link">
                <i class="fa-solid fa-arrow-up-right-from-square"></i>
            </div>
        </div>
    </div>
</div>