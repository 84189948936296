import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { first } from 'rxjs';
import { getTaskProgressById } from '../../state/task-handling.selector';
import { TaskUpdate } from '../../models/TaskUpdate';
import { editTaskAction } from '../../state/task-handling.actions';

@Component({
  selector: 'app-task-edit',
  templateUrl: './task-edit.component.html',
  styleUrls: ['./task-edit.component.scss']
})
export class TaskEditComponent {

  taskForm = this.fb.group({
    title: ["", [Validators.required]]
  });
  
  currentId = "";

  constructor(private fb: FormBuilder, private store: Store, private router: Router, private activeRoute: ActivatedRoute){
    this.currentId = this.activeRoute.snapshot.paramMap.get("id") as string;
  }
  ngOnInit(): void {
    this.store.select(getTaskProgressById(this.currentId))
    .pipe(first())
    .subscribe({
      next: (value) => {
        this.taskForm.setValue({title: value.title});
      }
    });
  }

  updateTask() {
    let task = new TaskUpdate();
    task.id = this.currentId;
    task.title = this.taskForm.value.title || "---";
    task.isActive = true;

    // Dispatch
    this.store.dispatch(editTaskAction({id: this.currentId, data: task}));

    this.router.navigateByUrl("/admin/tasks");
  }
}
