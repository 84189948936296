import { createFeatureSelector, createSelector, select } from "@ngrx/store";
import * as fromAuthorization from "./authorization.reducer";
import { UserRight } from "../models/UserRight";
import { User } from "../models/User";

export const selectAuthorizationState = createFeatureSelector<fromAuthorization.State>(
    fromAuthorization.authFeatureKey
);

export const selectIsUserLoggedIn = createSelector(
    selectAuthorizationState,
    (auth: fromAuthorization.State):boolean => {
        return !!auth.user;
    }
);

export const selectIsUserLoggedOut = createSelector(
    selectAuthorizationState,
    (auth: fromAuthorization.State):boolean => {
        return !auth.user;
    }
);

export const selectLoggedInUser = createSelector(
    selectAuthorizationState,
    (auth: fromAuthorization.State): User => {
        return auth.user ?? new User();
    }
);

export const selectUserRights = createSelector(
    selectAuthorizationState,
    (auth: fromAuthorization.State) : UserRight[] => {
        return auth.user?.rights || [];
    }
);

export const dataReadyForUse = createSelector(
    selectAuthorizationState,
    (auth: fromAuthorization.State): boolean => {
        return auth.init;
    }
);

export const availableLogins = createSelector(
    selectAuthorizationState,
    (auth: fromAuthorization.State) : User[] => {
        return auth.availableLogins;
    }
);