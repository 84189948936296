import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TaskHandlingRoutingModule } from './task-handling-routing.module';
import { TaskCreateComponent } from './views/task-create/task-create.component';
import { TaskListComponent } from './views/task-list/task-list.component';
import { TaskEditComponent } from './views/task-edit/task-edit.component';
import { ReactiveFormsModule } from '@angular/forms';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { reducer, tasksFeatureKey } from './state/task-handling.reducer';
import { TasksEffects } from './state/task-handling.effects';
import { BasicControlsModule } from '../basic-controls/basic-controls.module';


@NgModule({
  declarations: [
    TaskCreateComponent,
    TaskListComponent,
    TaskEditComponent
  ],
  imports: [
    CommonModule,
    TaskHandlingRoutingModule,
    ReactiveFormsModule,
    BasicControlsModule,
    StoreModule.forFeature(tasksFeatureKey, reducer),
    EffectsModule.forFeature([
      TasksEffects
    ])
  ]
})
export class TaskHandlingModule { }
