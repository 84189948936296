<div class="container">
    <h2>Instrument anlegen</h2>

    <form [formGroup]="instrumentForm" (ngSubmit)="createInstrument()" autocomplete="off">
        <div class="input-group">
            <label for="name">* Name</label>
            <input placeholder="z.B. Trompete" id="name" type="text" class="text-input" [class.error]="instrumentForm.controls['name'].touched && !instrumentForm.controls['name'].valid" formControlName="name">
            <p *ngIf="instrumentForm.controls['name'].touched && !instrumentForm.controls['name'].valid"  class="error-text" >
                Name ist erforderlich.
            </p>
        </div>
        <button type="submit" class="btn-primary" [disabled]="!instrumentForm.valid">Erstellen</button>
        <button type="button" class="btn-secondary" routerLink="/admin/instruments">Zurück</button>
    </form>
</div>
