import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import * as TasksActions from "./task-handling.actions";
import { tap } from "rxjs";
import { TaskHandlingService } from "../services/task-handling.service";

@Injectable()
export class TasksEffects {

    requestTasksEffect = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(TasksActions.loadTasksAction), 
                tap( () => this.taskHandlingService.loadAllTasks())
            );
        },
        {
            dispatch: false
        }
    );

    requestOpenTasksEffect = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(TasksActions.loadOpenTasksAction), 
                tap( () => this.taskHandlingService.loadAllOpenTasks())
            );
        },
        {
            dispatch: false
        }
    );

    addTaskEffect = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(TasksActions.addTaskAction),
                tap(task => this.taskHandlingService.addTask(task))
            );
        },
        {
            dispatch: false
        }
    );

    editTaskEffect = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(TasksActions.editTaskAction),
                tap((data) => this.taskHandlingService.editTask(data.id, data.data))
            );
        },
        {
            dispatch: false
        }
    );

    deleteTaskEffect = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(TasksActions.deleteTaskAction),
                tap((data) => this.taskHandlingService.deleteTask(data.id))
            );
        },
        {
            dispatch: false
        }
    );

    updateTaskStateEffect = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(TasksActions.updateTaskState),
                tap((data) => this.taskHandlingService.updateTaskProgress(data.data, data.lId))
            );
        },
        {
            dispatch: false
        }
    );

    constructor(private actions$: Actions, private taskHandlingService : TaskHandlingService){

    }
}