import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { Lesson } from '../../models/Lesson';

@Component({
  selector: 'app-general-card',
  templateUrl: './general-card.component.html',
  styleUrls: ['./general-card.component.scss']
})
export class GeneralCardComponent {
  @Input() lessonId: string = "";
  @Input() lesson$!: Observable<Lesson>;

  formatDate(dateString: any) {
    let date = new Date(Date.parse(dateString));
  
    let day = date.getDate();
    let month = date.getMonth() + 1;
  
    let str = "";
    str += ((day < 10) ? "0" : "") + day.toString() + ".";
    str += ((month < 10) ? "0" : "") + month.toString() + ".";
  
    return str + date.getFullYear();
  }
}
