import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import * as CommentsAction from "./comment.action";
import { tap } from "rxjs";
import { CommentService } from "../services/comment.service";

@Injectable()
export class CommentsEffects {

    constructor(private commentService: CommentService, private actions$: Actions){}

    requestLessonsEffect = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(CommentsAction.loadCommentsAction), 
                tap( () => this.commentService.loadAllComments())
            );
        },
        {
            dispatch: false
        }
    );

    addCommentEffect = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(CommentsAction.addCommentAction), 
                tap( data => this.commentService.addComment(data.data))
            );
        },
        {
            dispatch: false
        }
    );

    updateCommentEffect = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(CommentsAction.updateCommentAction), 
                tap( data => this.commentService.updateComment(data.id, data.data, data.lId))
            );
        },
        {
            dispatch: false
        }
    );

    removeCommentEffect = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(CommentsAction.removeCommentAction), 
                tap( data => this.commentService.removeComment(data.id, data.lId))
            );
        },
        {
            dispatch: false
        }
    );
}