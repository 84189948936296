import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BasicGuard } from '../authentication/guards/basic.guard';
import { OverviewComponent } from './views/overview/overview.component';
import { LessonsListComponent } from './views/lessons-list/lessons-list.component';
import { LessonPreparationComponent } from './views/lesson-preparation/lesson-preparation.component';
import { LessonExecutionComponent } from './views/lesson-execution/lesson-execution.component';
import { LessonReworkingComponent } from './views/lesson-reworking/lesson-reworking.component';
import { LessonDetailsComponent } from './views/lesson-details/lesson-details.component';
import { LessonAddComponent } from './views/lesson-add/lesson-add.component';
import { LessonEditComponent } from './views/lesson-edit/lesson-edit.component';

const routes: Routes = [
  {
    path: "lessons",
    canActivate: [BasicGuard],
    children: [
      /*{
        path:"",
        component: OverviewComponent
      },*/
      {
        path:"",
        component: LessonsListComponent
      },
      {
        path:"add",
        component: LessonAddComponent
      },
      {
        path:":id/edit",
        component: LessonEditComponent
      },
      {
        path:":id/preparation",
        component: LessonPreparationComponent
      },
      {
        path:":id/details",
        component: LessonDetailsComponent
      },
      {
        path:":id/execution",
        component: LessonExecutionComponent
      },
      {
        path:":id/reworking",
        component: LessonReworkingComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LessonsRoutingModule { }
