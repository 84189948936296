import { createFeatureSelector, createSelector, select } from "@ngrx/store";
import * as fromLessons from "./lesson.reducer";
import * as fromTasks from "../../task-handling/state/task-handling.reducer";
import { Lesson } from "../models/Lesson";
import { filter } from "rxjs";
import { allOpenTasks, allTasksInRawFormat } from "../../task-handling/state/task-handling.selector";
import { TaskRaw } from "../../task-handling/models/TaskRaw";
import { TaskProgressState } from "../../task-handling/models/TaskProgressState";
import { availableParticipants } from "../../persons/state/persons.selector";
import { ParticipationStateType } from "../models/ParticipationStateType";
import { Task } from "../../task-handling/models/Task";



export const lessonsState = createFeatureSelector<fromLessons.State>(
    fromLessons.lessonsFeatureKey
);

export const allLessons = createSelector(
    lessonsState,
    (lessons: fromLessons.State) : Lesson[] => {
        return lessons.lessons.map(x=>x).sort((a, b) => (Date.parse(b.eventDate.toString()) - Date.parse(a.eventDate.toString())));
    }
);

export const getLessonById = (id: string) => createSelector(
    lessonsState,
    (lessons: fromLessons.State) : Lesson => {
        return lessons.lessons.find(l => l.id === id) || new Lesson();
    }
);

export const currentLessonOrUpcomming = createSelector(
    lessonsState,
    (lessons: fromLessons.State) : Lesson | undefined => {
        let raw = lessons.lessons;

        let filterdLessons = raw.filter(l => CheckToday(l.eventDate as string)).sort((b, a ) => new Date(Date.parse(a.eventDate as string)).valueOf() - new Date(Date.parse(b.eventDate as string)).valueOf());
        
        if (filterdLessons.length === 0)
            return undefined;
        
        let lesson = filterdLessons[filterdLessons.length - 1];
        return lesson;
    }
);

export const lessonStateWithTasks = (lessonId: string) => createSelector(
    getLessonById(lessonId),
    (lesson) => {
        let notAdvisedTasks = [];
        for(let i = 0; i < lesson.openTasksBeforeStart.length; i++){
            if(lesson.tasks.findIndex(t => t.id === lesson.openTasksBeforeStart[i].id) === -1)
                notAdvisedTasks.push(lesson.openTasksBeforeStart[i]);
        }

        return notAdvisedTasks;
    }
);

export const taskOfParticipantForLesson = (personId: string, lessonId: string) => createSelector(
    getLessonById(lessonId),
    (lesson) => {
        if (lesson.id === "")
            return [];

        let openTaskPerPerson = lesson.openTasksBeforeStart.filter(t => t.progress.filter(prog => prog.personId === personId).length !== 0);
        
        return openTaskPerPerson.filter(t => lesson.tasks.findIndex(lT => lT.id == t.id) !== -1);
    }
);

export const isParticipantAdvised = (personId: string, lessonId: string) => createSelector(
    getLessonById(lessonId),
    (lesson) => {
        return lesson.groups.findIndex(g => g.participants.findIndex(p => p.id === personId) !== -1) !== -1;
    }
);

export const isInstructorAdvised = (personId: string, lessonId: string) => createSelector(
    getLessonById(lessonId),
    (lesson) => {
        return lesson.groups.findIndex(g => g.instructors.findIndex(p => p.id === personId) !== -1) !== -1;
    }
);

export const getParticipationStateOfPersonById = (personId : string, lessonId: string) => createSelector(
    lessonsState,
    (lessons) => {
        let participtionLesson = lessons.participation.find(p => p.lId === lessonId);

        if (participtionLesson === null || participtionLesson === undefined)
            return ParticipationStateType.UNDEFINED;

        let person = participtionLesson.data.find(p => p.personId === personId);

        if (person === null || person === undefined)
            return ParticipationStateType.UNDEFINED;

        return person.state;
    }
);

export const getGroupForLessonByPerson = (personId : string, lessonId: string) => createSelector(
    getLessonById(lessonId),
    (lesson) => {
       return lesson.groups.find(g => g.participants.findIndex(p => p.id === personId) !== -1 || g.instructors.findIndex(p => p.id == personId) !== -1);
    }
);

export const getAllPractiseGroupsByLesson = (lessonId: string) => createSelector(
    getLessonById(lessonId),
    (lesson) => {
        return lesson.groups;
    }
);

export const getPractiseGroupsById = (lessonId: string, grpId: string) => createSelector(
    getLessonById(lessonId),
    (lesson) => {
        return lesson.groups.find(g => g.id === grpId);
    }
);

export const getAmountOfParticipantsWithSameInstructor = (personId: string, newGroup: string, lessonId: string) => createSelector(
    lessonsState,
    (lessons) => {
        // last idx + 1
        let idx = lessons.lessons.findIndex(l  => l.id === lessonId);
        if((idx + 1) == lessons.lessons.length)
            return 0;
        
        let lastLesson = lessons.lessons[idx + 1];
        let currentLesson = lessons.lessons[idx];

        let amount = 0;
        let lastGroup = lastLesson.groups.find(g => g.instructors.findIndex(i => i.id === personId) !== -1);

        if (lastGroup === undefined)
            return 0;
        
        let currentGroup = currentLesson.groups.find(g => g.id === newGroup);

        if (currentGroup === undefined)
            return 0;   

        currentGroup.participants.forEach(p => {
            if (lastGroup?.participants.findIndex(pOld => p.id === pOld.id) !== -1)
                amount++;
        });


        return amount;
    }
);


function CheckToday(str: string) : boolean {
    let currentDate = new Date();
    let dataDate = new Date(Date.parse(str));

    if (currentDate.getDate() === dataDate.getDate() && currentDate.getMonth() === dataDate.getMonth() && currentDate.getFullYear() === dataDate.getFullYear())
        return true;
    else if(currentDate < dataDate)
        return true;
    else 
        return false;
}


