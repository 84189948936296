import packageJson from '../../../../package.json';
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { logoutAction } from '../authentication/state/registration.action';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  public version: string = packageJson.version;

  constructor(private store : Store) { }

  ngOnInit(): void {
  }

  logoutTemp() {
    this.store.dispatch(logoutAction());
  }

}
