<form [formGroup]="addPractiseGroupForm" (ngSubmit)="close(true)" autocomplete="off">
    <header>
        <h2>Übungsgruppe hinzufügen</h2>
    </header>
    <app-input-field 
            inputId="name"
            label="Gruppenname"
            placeholder="z.B. Gruppe 1"
            [control]="addPractiseGroupForm.controls['name']"
            errorMessage="Gruppenname ist erforderlich"
            >
        </app-input-field>
    <footer>
        <button type="submit" [disabled]="!addPractiseGroupForm.valid"  id="add-group" class="btn-primary">Hinzufügen</button>
        <button (click)="close(false)" id="add-group" type="button" class="btn-secondary">Abbrechen</button>
    </footer>
</form>
