import { createFeatureSelector, createSelector } from "@ngrx/store";

import * as fromPersons from "./persons.reducer";
import { Person } from "../models/Person";
import { CONFIG } from "src/app/configuration";

export const personsState = createFeatureSelector<fromPersons.State>(
    fromPersons.personsFeatureKey
);

export const availablePersons = createSelector(
    personsState,
    (persons: fromPersons.State) : Person[] => {
        return persons.persons;
    }
);

export const availableParticipants = createSelector(
    personsState,
    (persons: fromPersons.State) : Person[] => {
        return persons.persons.filter(person => person.role.id === CONFIG.PARTICIPANT_ID);
    }
);

export const availableInstructors = createSelector(
    personsState,
    (persons: fromPersons.State) : Person[] => {
        return persons.persons.filter(person => person.role.id === CONFIG.INSTRUCTOR_ID);
    }
);

export const getPersonById = (id: string) => createSelector(
    personsState,
    (persons: fromPersons.State) : Person => {
        return persons.persons.find(pers => pers.id === id) || new Person();
    }
);