import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectLoggedInUser } from '../../state/authorization.selector';

@Component({
  selector: 'app-profile-page',
  templateUrl: './profile-page.component.html',
  styleUrls: ['./profile-page.component.scss']
})
export class ProfilePageComponent {

  user$ = this.store.select(selectLoggedInUser).pipe();

  constructor(private store: Store) {

  }
}
