import { createReducer, on } from "@ngrx/store";
import { User } from "../models/User";
import * as AuthenticationActions from "./registration.action";

export const authFeatureKey = "auth";

export interface State {
    user: null | User,
    availableLogins: User[],
    init: boolean
}

export const initalState: State = {
    user: null,
    availableLogins: [],
    init: false
}

export const reducer = createReducer(
    initalState,
    on(AuthenticationActions.logoutAction, (state) : State => {
        return {
            ...state,
            user: null,
            init: true
        }
    }),
    on(AuthenticationActions.loginSuccessAction, (state, {user}) : State => {
        return {
            ... state,
            user: user,
            init: true
        }
    }),
    on(AuthenticationActions.refreshSuccessAction, (state, {user}) : State => {
        return {
            ... state,
            user: user,
            init: true
        }
    }),
    on(AuthenticationActions.getLoginsAction, (state, {users}) : State => {
        return {
            ... state,
            availableLogins: users
        }
    })
);