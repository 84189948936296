import { Component } from '@angular/core';
import { Instrument } from '../../models/Instrument';
import { Store } from '@ngrx/store';
import { deleteInstrumentAction, loadInstrumentsAction } from '../../state/instruments.actions';
import { availableInstruments } from '../../state/instruments.selector';

@Component({
  selector: 'app-instuments-administration',
  templateUrl: './instuments-administration.component.html',
  styleUrls: ['./instuments-administration.component.scss']
})
export class InstumentsAdministrationComponent {
  instruments$ = this.store.select(availableInstruments).pipe();

  constructor(private store: Store) {
    this.store.dispatch(loadInstrumentsAction());
  }

  deleteInstrument(id: string) {
    this.store.dispatch(deleteInstrumentAction({id}));
  }
}
