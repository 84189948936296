<div class="container">
    <h2>Übungsstunde kommentieren</h2>
    <!-- General information -->
    <app-general-card [lesson$]="currentLesson$" [lessonId]="currentId"></app-general-card>

    <!-- Lesson comments -->
    <app-comment-card 
    title="Kommentare - Übungsstunde"
    [lessonId]="currentId"
    [groupId]="null"
    [personId]="null"
    ></app-comment-card>

    <!-- Group selection -->
    <div 
        *ngIf="currentGroupId === null"
        class="group-selection" >
        <p class="content">Keine Gruppe ausgewählt! Zum Kommentieren eine Gruppe auswählen.</p>
        <button (click)="openGroupSelection()" class="btn btn-primary btn-label">
            <i class="fa-regular fa-square-check"></i>
            Gruppe auswählen
        </button>
    </div>
    <div 
        *ngIf="currentGroupId !== null"
        class="group-selection">
        <p class="content">Gruppe {{currentGroup?.groupName}} ausgewählt!</p>
        <button (click)="openGroupSelection()" class="btn btn-primary btn-label">
            <i class="fa-solid fa-repeat"></i>
            Gruppe wechseln
        </button>
    </div>

    <!-- Group comments -->
    <app-comment-card 
    *ngIf="currentGroupId !== null"
    title="Kommentare - Übungsgruppe"
    [lessonId]="currentId"
    [groupId]="currentGroupId"
    [personId]="null"
    ></app-comment-card>

    <!-- Participants of group #todo -->
    <app-accordion
        class="participant"
        *ngFor="let participant of currentGroup?.participants"
        [errorColor]="false"
        [actionRequired]="false"
        [errorColor]="false"
        [collapsed]="false"
        [subtitle]="participant.instrument.name"
        [title]="participant.name + ', ' + participant.prename"
    >

        <!-- Participant comments -->
        <app-comment-card 
        title="Kommentare - Teilnehmer"
        [lessonId]="currentId"
        [groupId]="currentGroupId"
        [personId]="participant.id"
        [opend]="true"
        ></app-comment-card>

        <!-- Participant tasks #todo -->
        <app-accordion
            [errorColor]="false"
            [actionRequired]="false"
            [errorColor]="false"
            [collapsed]="false"
            title="Übungsfortschritt"
        >
            <ul>
                <li 
                    *ngFor="let task of getOpenTasksForParticipant(participant.id) | async"
                    class="task-entry">
                    <div class="task-label">
                        <p class="content desktop">{{task.title}} (Kap: {{task.chapter}}, Nr. {{task.number}})</p>
                        <p class="content mobile">{{task.title}}</p>
                        <p class="content mobile">(Kap: {{task.chapter}}, Nr. {{task.number}})</p>
                    </div>
                    <div class="task-progress" *ngIf="(getTaskProgress(task.id, participant.id)| async)?.state === 2">
                            <i class="fa-solid fa-check"></i><p>Done</p>
                    </div>
                    <button 
                        (click)="reopenTask(getTaskProgress(task.id, participant.id))"
                        *ngIf="(getTaskProgress(task.id, participant.id)| async)?.state === 2"
                        class="btn btn-secondary btn-ico">
                        <i class="fa-solid fa-arrow-rotate-left"></i>
                    </button>
                    <button 
                        (click)="finishTask(getTaskProgress(task.id, participant.id))"
                        *ngIf="(getTaskProgress(task.id, participant.id)| async)?.state !== 2"
                        class="btn btn-secondary btn-ico">
                        <i class="fa-regular fa-circle-check"></i>
                    </button>
                </li>
                <p *ngIf="(getOpenTasksForParticipant(participant.id) | async)?.length === 0" class="helper">Keine
                    offenen Übungen vorhanden</p>
            </ul>  
        </app-accordion>

    </app-accordion>
</div>
