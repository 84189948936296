<form [formGroup]="commentForm" (ngSubmit)="close(true)" autocomplete="off">
    <header>
        <h2 *ngIf="!data.updateMode">{{title}} hinzufügen</h2>
        <h2 *ngIf="data.updateMode">{{title}} bearbeiten</h2>
    </header>
    <app-input-field 
            inputId="content"
            label="Kommentar"
            placeholder="z.B. Das ist ein Kommentar"
            [control]="commentForm.controls['content']"
            errorMessage="Inhalt ist erforderlich"
            >
        </app-input-field>
    <footer>
        <button *ngIf="!data.updateMode" type="submit" [disabled]="!commentForm.valid"  id="add-group" class="btn-primary">Hinzufügen</button>
        <button *ngIf="data.updateMode" type="submit" [disabled]="!commentForm.valid"  id="add-group" class="btn-primary">Aktualisieren</button>
        <button (click)="close(false)" id="add-group" type="button" class="btn-secondary">Abbrechen</button>
    </footer>
</form>
