import { createFeatureSelector, createSelector, select } from "@ngrx/store";
import * as fromInstruments from "./instruments.reducer";
import { Instrument } from "../models/Instrument";

export const instrumentsState = createFeatureSelector<fromInstruments.State>(
    fromInstruments.instrumentsFeatureKey
);

export const availableInstruments = createSelector(
    instrumentsState,
    (instruments: fromInstruments.State) : Instrument[] => {
        return instruments.instruments;
    }
);

export const getInstrumentById = (id: string) => createSelector(
    instrumentsState,
    (instruments: fromInstruments.State) : Instrument => {
        return instruments.instruments.find(instr => instr.id === id) || new Instrument();
    }
);

