import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { deleteTaskAction, loadTasksAction } from '../../state/task-handling.actions';
import { allTasksInRawFormat } from '../../state/task-handling.selector';

@Component({
  selector: 'app-task-list',
  templateUrl: './task-list.component.html',
  styleUrls: ['./task-list.component.scss']
})
export class TaskListComponent {
  tasksRaw$ = this.store.select(allTasksInRawFormat).pipe();

  constructor(private store: Store) {
    this.store.dispatch(loadTasksAction());
  }

  deleteTask(id: string) {
    this.store.dispatch(deleteTaskAction({id: id}));
  }
}
