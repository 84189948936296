import { Component, Inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { map } from 'rxjs';
import { topOpenTasks } from 'src/app/modules/task-handling/state/task-handling.selector';
import { lessonStateWithTasks } from '../../state/lesson.selector';

@Component({
  selector: 'app-add-task-to-lesson',
  templateUrl: './add-task-to-lesson.component.html',
  styleUrls: ['./add-task-to-lesson.component.scss']
})
export class AddTaskToLessonComponent {

  openNotAdvisedTasks$ = this.store.select(lessonStateWithTasks(this.data.lessonId)).pipe(map(tasks => tasks.map(task => {
    return {key: task.id, label: task.title + " (Kap: "+ task.chapter + " Nr: " + task.number+")"};
  })));

  addTaskForm = this.fb.group({
    task: ["", [Validators.required]]
  });
  
  constructor(@Inject(MAT_DIALOG_DATA) public data: {lessonId: string}, public dialogRef : MatDialogRef<AddTaskToLessonComponent>, private fb: FormBuilder, private store: Store) {

  }

  close(add: boolean) {
    if (!add){
      this.dialogRef.close();
    } else {
      this.dialogRef.close(this.addTaskForm.value.task);
    }
  }
}
