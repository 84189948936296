<div class="input-group">
    <label [for]="inputId"><span *ngIf="required">* </span>{{label}}</label>
    <input 
        [formControl]="control"
        [placeholder]="placeholder" 
        [id]="inputId" 
        [type]="type" 
        class="text-input" 
        [class.error]="control.touched && !control.valid">
    <p *ngIf="control.touched && !control.valid && errorMessage !== ''"  class="error-text" >
        {{errorMessage}}
    </p>
</div>
