<div class="container">
    <h2>Aufgabe bearbeiten</h2>

    <form [formGroup]="taskForm" (ngSubmit)="updateTask()" autocomplete="off">

        <app-input-field 
            inputId="title"
            label="Titel"
            placeholder="z.B. Affenschaukel"
            [control]="taskForm.controls['title']"
            errorMessage="Titel ist erforderlich"
            >
        </app-input-field>
        
        <button type="submit" class="btn-primary" [disabled]="!taskForm.valid">Updaten</button>
        <button type="button" class="btn-secondary" routerLink="/admin/tasks">Zurück</button>
    </form>
</div>
