<div class="container">
    <h2>Übungsstunde bearbeiten</h2>

    <form [formGroup]="lessonForm" (ngSubmit)="updateLesson()" autocomplete="off">

        <app-input-field 
            inputId="eventDate"
            label="Datum"
            placeholder="01.01.2023"
            [control]="lessonForm.controls['eventDate']"
            errorMessage="Datum ist erforderlich"
            type="date"
            >
        </app-input-field>
        <app-drop-down 
            inputId="impulseHeldBy"
            label="Wer hält die Andacht?"
            [entries$]="instructors$"
            [control]="lessonForm.controls['impulseHeldBy']"
            errorMessage=""
            [required]="false"
            >
        </app-drop-down>
        <app-input-field 
            inputId="impulseTopic"
            label="Thema der Andacht"
            placeholder="Dankbarkeit"
            [control]="lessonForm.controls['impulseTopic']"
            errorMessage=""
            [required]="false"
            >
        </app-input-field>
        
        <button type="submit" class="btn-primary" [disabled]="!lessonForm.valid">Speichern</button>
        <button type="button" class="btn-secondary" (click)="this.back()">Zurück</button>
    </form>
</div>

