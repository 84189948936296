import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DropDownEntry } from '../../models/DropDownEntry';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-drop-down',
  templateUrl: './drop-down.component.html',
  styleUrls: ['./drop-down.component.scss']
})
export class DropDownComponent {
  @Input() inputId: string = "";
  @Input() control = new FormControl();
  @Input() label = "";
  @Input() entries$ = new Observable<DropDownEntry[]>();
  @Input() errorMessage = "";
  @Input() required = true;
}
