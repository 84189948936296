import { Injectable } from "@angular/core";
import { tap } from "rxjs";
import { Actions, createEffect, ofType } from "@ngrx/effects";

import { PersonService } from "../services/person.service";
import * as PersonsActions from "./persons.actions";


@Injectable()
export class PersonsEffects {
    requestPersonsEffect = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(PersonsActions.loadPersonsAction),
                tap(() => this.personService.loadAllActivePersons())
            );
        },
        { dispatch: false }
    );

    addPersonEffect = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(PersonsActions.addPersonAction),
                tap((data) => this.personService.addPerson(data.data))
            );
        },
        { dispatch: false }
    );

    editPersonEffect = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(PersonsActions.editPersonAction),
                tap(data => this.personService.editPerson(data.id, data.data))
            );
        },
        { dispatch: false }
    );

    deletePersonEffect = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(PersonsActions.deletePersonAction),
                tap(data => this.personService.deletePerson(data.id))
            );
        },
        { dispatch: false}
    );

    constructor(private actions$: Actions, private personService : PersonService){

    }
};