<h2>Registrierung</h2>

<form [formGroup]="registerForm" (ngSubmit)="sendRegistration()" autocomplete="off">
    <div class="input-group">
        <label for="prename">* Vorname</label>
        <input id="prename" type="text" class="text-input" [class.error]="registerForm.controls['prename'].touched && !registerForm.controls['prename'].valid" formControlName="prename">
        <p *ngIf="registerForm.controls['prename'].touched && !registerForm.controls['prename'].valid"  class="error-text" >
            Vorname ist erforderlich.
        </p>
    </div>
    <div class="input-group">
        <label for="name">* Nachname</label>
        <input id="name" type="text" class="text-input" [class.error]="registerForm.controls['name'].touched && !registerForm.controls['name'].valid" formControlName="name">
        <p class="error-text" *ngIf="registerForm.controls['name'].touched && !registerForm.controls['name'].valid">
            Nachname ist erforderlich.
        </p>
    </div>
    <div class="input-group">
        <label for="email">* Email-Adresse</label>
        <input id="email" type="email" class="text-input" [class.error]="registerForm.controls['email'].touched && !registerForm.controls['email'].valid" formControlName="email">
        <p class="error-text" *ngIf="registerForm.controls['email'].touched && !registerForm.controls['email'].valid">
            Gültige Email-Addresse ist erforderlich.
        </p>
    </div>
    <div class="input-group">
        <label for="password">* Passwort</label>
        <input id="password" type="password" class="text-input" [class.error]="registerForm.controls['password'].touched && !registerForm.controls['password'].valid" formControlName="password" >
        <p class="error-text" *ngIf="registerForm.controls['password'].touched && !registerForm.controls['password'].valid">
            Passwort ist erforderlich und muss mindestens 8 Zeichen lang sein.
        </p>
    </div>
    <div class="input-group">
        <label for="passwordRepeat">* Passwort wiederholen</label>
        <input id="passwordRepeat" type="password" class="text-input" [class.error]="registerForm.controls['passwordRepeat'].touched && !registerForm.controls['passwordRepeat'].valid" formControlName="passwordRepeat">
        <p class="error-text" *ngIf="registerForm.controls['passwordRepeat'].touched && !registerForm.controls['passwordRepeat'].valid">
            Passwörter stimmen nicht überein.
        </p>
    </div>
    <button type="submit" class="btn-primary" [disabled]="!registerForm.valid">Registrieren</button>
    <button type="button" class="btn-secondary" routerLink="/login">Zurück</button>
</form>