import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, first } from 'rxjs';
import { Comment } from 'src/app/modules/comments/models/Comment';
import { getCommentForGroup, getCommentForLesson, getCommentForPersonAndLesson } from 'src/app/modules/comments/state/comment.selector';
import { MatDialog } from '@angular/material/dialog';
import { CommentOverlayComponent } from 'src/app/modules/comments/components/comment-overlay/comment-overlay.component';
import { addCommentAction, loadCommentsAction, removeCommentAction, updateCommentAction } from '../../state/comment.action';

@Component({
  selector: 'app-comment-card',
  templateUrl: './comment-card.component.html',
  styleUrls: ['./comment-card.component.scss']
})
export class CommentCardComponent implements OnInit, OnChanges {
  @Input() title = "";
  @Input() lessonId : string | null = null;
  @Input() groupId : string | null = null;
  @Input() personId : string | null = null;
  @Input() opend = false;

  commentType: "LESSON" | "GROUP" | "PERSON" = "LESSON";

  comments$! : Observable<Comment[]>;

  constructor(private store: Store, private dialog: MatDialog){ }

  ngOnInit(): void {
    if (this.personId !== null){
      this.commentType = "PERSON";
      this.comments$ = this.store.select(getCommentForPersonAndLesson(this.lessonId || "", this.personId || "", 10));
    } else if( this.groupId !== null){
      this.commentType = "GROUP";
      this.comments$ = this.store.select(getCommentForGroup(this.groupId || "", 10));
    } else {
      this.commentType = "LESSON";
      this.comments$ = this.store.select(getCommentForLesson(this.lessonId || "", 10));
    }
    
  }

  ngOnChanges(): void {
    if (this.personId !== null){
      this.commentType = "PERSON";
      this.comments$ = this.store.select(getCommentForPersonAndLesson(this.lessonId || "", this.personId || "", 10));
    } else if( this.groupId !== null){
      this.commentType = "GROUP";
      this.comments$ = this.store.select(getCommentForGroup(this.groupId || "", 10));
    } else {
      this.commentType = "LESSON";
      this.comments$ = this.store.select(getCommentForLesson(this.lessonId || "", 10));
    }
  }

  addComment() {
    let dialogRef = this.dialog.open(CommentOverlayComponent, {
      width: "80%",
      maxHeight: "80%",
      data: {
        updateMode: false,
        content: null,
        commentType: this.commentType
      }
    });
    
    dialogRef.afterClosed()
    .pipe(first())
    .subscribe(result => {
      if (result !== null && result !== undefined)
        this.store.dispatch(addCommentAction({ data: {
          content: result,
          lessonId: this.lessonId,
          personId: this.personId,
          practiseGroupId: this.groupId
        }}));
    });
  }

  editComment(id: string, content: string, lId: string) {
    let dialogRef = this.dialog.open(CommentOverlayComponent, {
      width: "80%",
      maxHeight: "80%",
      data: {
        updateMode: true,
        content: content
      }
    });
    
    dialogRef.afterClosed()
    .pipe(first())
    .subscribe(result => {
      if (result !== null && result !== undefined)
        this.store.dispatch(updateCommentAction({ id: id, data: {
          content: result
        }, lId: lId
      }))
  });
  }

  deleteComment(id: string, lId: string) {
    this.store.dispatch(removeCommentAction({id: id, lId: lId}));
  }
}

