import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminGuard } from '../authentication/guards/admin.guard';
import { StudentOverviewComponent } from './views/student-overview/student-overview.component';
import { InstructorOverviewComponent } from './views/instructor-overview/instructor-overview.component';
import { PersonCreateComponent } from './components/person-create/person-create.component';
import { PersonEditComponent } from './components/person-edit/person-edit.component';

const routes: Routes = [
  {
    path: "admin",
    canActivate: [AdminGuard],
    children: [
      {
        path: "students",
        children: [
          {
            path:"",
            component: StudentOverviewComponent
          },
          {
            path: "add",
            component: PersonCreateComponent
          },
          {
            path: "edit/:id",
            component: PersonEditComponent
          }
        ]
      },
      {
        path: "instructors",
        children: [
          {
            path: "",
            component: InstructorOverviewComponent
          },
          {
            path: "add",
            component: PersonCreateComponent
          },
          {
            path: "edit/:id",
            component: PersonEditComponent
          }
        ]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PersonsRoutingModule { }
