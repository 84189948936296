import { createAction, props } from "@ngrx/store";
import { Person } from "../models/Person";
import { PersonCreate } from "../models/PersonCreate";
import { PersonUpdate } from "../models/PersonUpdate";

export const loadPersonsAction = createAction('[Persons] Load persons');

export const loadPersonsActionSuccess = createAction('[Persons] Load persons success', props<{data: Person[]}>());

export const addPersonAction = createAction('[Persons] Add person', props<{data: PersonCreate}>());

export const editPersonAction = createAction('[Persons] Edit person', props<{id: string, data: PersonUpdate}>());

export const deletePersonAction = createAction('[persons] Delete person', props<{id: string}>());

