import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { map } from 'rxjs';
import { availableInstructors } from 'src/app/modules/persons/state/persons.selector';
import { addLessonAction } from '../../state/lesson.actions';

@Component({
  selector: 'app-lesson-add',
  templateUrl: './lesson-add.component.html',
  styleUrls: ['./lesson-add.component.scss']
})
export class LessonAddComponent {

  
  instructors$ = this.store.select(availableInstructors).pipe(map(ps => ps.map(p => {
    return {key: p.id, label: p.name + ", " + p.prename};
  })));
  
  lessonForm = this.fb.group({
    eventDate: ["", [Validators.required]],
    impulseHeldBy: ["", []],
    impulseTopic: ["", []]
  });

  constructor(private fb: FormBuilder, private store: Store, private router: Router){
    
  }
  
  createLesson() {
    let rawDate = new Date(Date.parse(this.lessonForm.value.eventDate || "1970-01-01"));
    let date: string = "";
    date += rawDate.getFullYear() + "-";
    date += (rawDate.getMonth() + 1) + "-";
    date += rawDate.getDate();

    this.store.dispatch(addLessonAction({
      data: {
        eventDate:  date,
        impulseHeldBy: this.lessonForm.value.impulseHeldBy || null,
        impulseTopic: this.lessonForm.value.impulseTopic || null,
      }
    }));

    this.router.navigateByUrl("/lessons");
  }
}
