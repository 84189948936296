import { Component } from '@angular/core';
import { Message } from './modules/toaster/models/Message';
import { ToastService } from './modules/toaster/services/toast.service';
import { NavigationService } from './modules/basic-controls/services/navigation.service';
import { Store } from '@ngrx/store';
import { loadCommentsAction } from './modules/comments/state/comment.action';
import { loadOpenTasksAction, loadTasksAction } from './modules/task-handling/state/task-handling.actions';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'choir-administration';

  currentMessage: Message | null = null;

  constructor(private store: Store, private toastService: ToastService, private navigationService: NavigationService) {
    /* Dynamic height */
    document.documentElement.style.setProperty("--height", window.innerHeight.toString() + "px");
    window.addEventListener("resize", () => {
      document.documentElement.style.setProperty("--height", window.innerHeight.toString() + "px");
    });

    toastService.currentMessage$.subscribe(data => data.length != 0 ? this.currentMessage = data[0] : this.currentMessage = null);
  
    this.store.dispatch(loadCommentsAction());
    this.store.dispatch(loadTasksAction());
    this.store.dispatch(loadOpenTasksAction());
  }
}
