import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserCreate } from '../models/UserCreate';
import { Router } from '@angular/router';
import { ToastService } from '../../toaster/services/toast.service';
import { UserLogin } from '../models/UserLogin';
import jwtDecode from "jwt-decode";
import { Store } from '@ngrx/store';

import { getLoginsAction, loginSuccessAction, logoutAction, refreshSuccessAction, requestLoginAction } from "../state/registration.action";
import { environment } from 'src/environments/environment';
import { CONFIG } from 'src/app/configuration';
import { User } from '../models/User';

const ADD_URL = environment.baseUrl + "v1/register";
const LOGIN_URL = environment.baseUrl + "v1/login";
const REFRESH_URL = environment.baseUrl + "v1/login/token"

const REQ_ALL_USER = environment.baseUrl + "v1/users/choir/" + CONFIG.CHOIR_ID;
const LOGIN_ACTIVATE = environment.baseUrl + "v1/users/:id/activate";
const LOGIN_DEACTIVATE = environment.baseUrl + "v1/users/:id/deactivate";

const CHANGE_RIGHTS = environment.baseUrl + "v1/users/:id/rights";

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private httpClient: HttpClient, private store: Store, private router: Router, private toastService: ToastService) {
    this.refreshToken(this.httpClient, this.router);
    setInterval(() => {
      this.refreshToken(this.httpClient, this.router);
    }, 890000);
  }

  decodeJWTToken(token: string): any {
    return jwtDecode(token);
  }

  register(user: UserCreate) {
    this.httpClient.post(ADD_URL, user).subscribe({
      next: (data) => {
        this.router.navigateByUrl("/login");
      },
      error: (err) => {
        this.toastService.addMessage({
          heading: "Registrierungsfehler",
          data: "Die Email wird bereits durch einen anderen Benutzer verwendent.",
          type: "error"
        });
      }
    });
  }

  logout() {
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    
    this.router.navigateByUrl("/login");
  }

  login(user: UserLogin) {
    this.httpClient.post(LOGIN_URL, user).subscribe({
      next: (data: any) => {
        localStorage.setItem("access_token", data.token);
        localStorage.setItem("refresh_token", data.refreshToken);

        this.store.dispatch(loginSuccessAction({ user: this.decodeJWTToken(data.token) }));
        this.router.navigateByUrl("/");
      },
      error: (err) => {
        console.log("error");
        console.log(err.status);

        if (err.status == 400 || err.status == 401) {
          this.toastService.addMessage({
            heading: "Anmeldung fehlgeschlagen",
            data: "Email oder Passwort sind falsch",
            type: "error"
          });
        }
        else if (err.status == 404) {
          this.toastService.addMessage({
            heading: "Anmeldung fehlgeschlagen",
            data: "Server ist nicht erreichbar. Versuchen Sie es zu einem späteren Zeitpunkt erneut.",
            type: "error"
          });
        }
        else if (err.status == 500) {
          this.toastService.addMessage({
            heading: "Anmeldung fehlgeschlagen",
            data: "Server error",
            type: "error"
          });
        }
        else {
          this.toastService.addMessage({
            heading: "Anmeldung fehlgeschlagen",
            data: "Undefinierter Fehler",
            type: "error"
          });
        }
      }
    });
  }

  refreshToken(httpClient: HttpClient, router: Router) {
    let refreshToken = localStorage.getItem("refresh_token") || "";
    if (refreshToken == "") {
      router.navigateByUrl("/login");
      return;
    }

    httpClient.post(REFRESH_URL, null, {
      headers: new HttpHeaders().set("Authorization", "Bearer " + refreshToken)
    }).subscribe({
      next: (data: any) => {
        localStorage.setItem("access_token", data.token);
        localStorage.setItem("refresh_token", data.refreshToken);

        this.store.dispatch(refreshSuccessAction({ user: this.decodeJWTToken(data.token) }))
      },
      error: (err) => {
        console.log(err);
        router.navigateByUrl("/login");
        this.toastService.addMessage({ heading: "Sitzung ist abgelaufen", data: "Erneutes Anmelden erforderlich.", type: 'error' });
      }
    });
  }

  getAllUsers() {
    let token = localStorage.getItem("access_token") || "";
    this.httpClient.get<User[]>(REQ_ALL_USER, {
      headers: new HttpHeaders().set("Authorization", "Bearer " + token)
    }).subscribe({
      next: (users : User[]) => {
        this.store.dispatch(getLoginsAction({users: users}));
      },
      error: (err) => {
        console.log(err);
      }
    });
  }

  activateUser(id: string) {
    let token = localStorage.getItem("access_token") || "";
    this.httpClient.put(LOGIN_ACTIVATE.replace(":id", id), null, {
      headers: new HttpHeaders().set("Authorization", "Bearer " + token)
    }).subscribe({
      next: () => {
        this.store.dispatch(requestLoginAction());
      },
      error: (err) => console.log(err)
    });
  }

  deactivateUser(id: string){
    let token = localStorage.getItem("access_token") || "";
    this.httpClient.put(LOGIN_DEACTIVATE.replace(":id", id), null, {
      headers: new HttpHeaders().set("Authorization", "Bearer " + token)
    }).subscribe({
      next: () => {
        this.store.dispatch(requestLoginAction());
      },
      error: (err) => console.log(err)
    });
  }

  changeUserRights(id: string, rights: string[]) {
    let token = localStorage.getItem("access_token") || "";
    this.httpClient.put(CHANGE_RIGHTS.replace(":id", id), rights, {
      headers: new HttpHeaders().set("Authorization", "Bearer " + token)
    }).subscribe({
      next: () => {
        this.store.dispatch(requestLoginAction());
      },
      error: (err) => console.log(err)
    });
  }
}
