import { Component, Inject } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-comment-overlay',
  templateUrl: './comment-overlay.component.html',
  styleUrls: ['./comment-overlay.component.scss']
})
export class CommentOverlayComponent {

  commentType: "LESSON" | "GROUP" | "PERSON" = "LESSON";

  title = "Übungsstunden Kommentar";


  commentForm = this.fb.group({
    content: ["", [Validators.required]]
  });
  
  constructor(@Inject(MAT_DIALOG_DATA) public data: {updateMode: boolean, commentType: "LESSON" | "GROUP" | "PERSON", content: string}, public dialogRef : MatDialogRef<CommentOverlayComponent>, private fb: FormBuilder, private store: Store) {
    this.commentForm.setValue({content: data.content});

    this.commentType = data.commentType;

    switch (this.commentType) {
      case "LESSON":
        this.title = "Übungsstunden Kommentar";
        break;
      case "GROUP":
        this.title = "Übungsgruppen Kommentar";
        break;
      case "PERSON":
        this.title = "Jungbläser Kommentar";
        break;
      default:
        break;
    }
  }

  close(add: boolean) {
    if (!add){
      this.dialogRef.close();
    } else {
      this.dialogRef.close(this.commentForm.value.content);
    }
  }
}
