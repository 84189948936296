<div class="container">
    <h2>Jungbläser Anleiter</h2>
    <div class="toolbar">
        <button routerLink="/admin/instructors/add" type="button" class="btn-primary btn-add"><i class="fa-solid fa-plus"></i>Hinzufügen</button>
    </div>
    <div class="data">
        <div class="data-header">
            <h3 class="col">
                Name
            </h3>
            <h3 class="col">
                Vorname
            </h3>
            <h3 class="col">
                Instrument
            </h3>
            <h3 class="col">
                Stimme
            </h3>
            <h3 class="col">
            </h3>
        </div>

        <div *ngFor="let person of this.persons$ | async" class="data-entry">
            <div class="col name">
                {{person.name}}
            </div>
            <div class="col prename">
                {{person.prename}}
            </div>
            <div class="col instrument">
                {{person.instrument.name}}
            </div>
            <div class="col voice">
                {{person.voice.name}}
            </div>
            <div class="col action">
                <button type="button" class="btn-secondary btn-add" [routerLink]="['/admin/instructors/edit/', person.id]">
                    <i class="fa-solid fa-pen"></i>
                </button>
                <button type="button" class="btn-primary btn-add" (click)="this.deletePerson(person.id)">
                    <i class="fa-solid fa-trash"></i>
                </button>
            </div>
        </div>
    </div>
</div>