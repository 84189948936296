import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TaskCreate } from '../../models/TaskCreate';
import { addTaskAction } from '../../state/task-handling.actions';

@Component({
  selector: 'app-task-create',
  templateUrl: './task-create.component.html',
  styleUrls: ['./task-create.component.scss']
})
export class TaskCreateComponent {

  taskForm = this.fb.group({
    title: ["", [Validators.required]],
    series: [0, []],
    chapter: [0, []],
    number: [0, []]
  });

  constructor(private fb: FormBuilder, private store: Store, private router: Router){
    
  }

  createTask() {
    let task = new TaskCreate();
    task.title = this.taskForm.value.title || "---";
    task.series = this.taskForm.value.series || 0;
    task.chapter = this.taskForm.value.chapter || 0;
    task.number = this.taskForm.value.number || 0;

    // Dispatch
    this.store.dispatch(addTaskAction(task));

    this.router.navigateByUrl("/admin/tasks");
  }
}
