import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Lesson } from '../models/Lesson';
import { environment } from 'src/environments/environment';
import { loadLessonByIdAction, loadLessonsAction, loadLessonsFailAction, loadParticipationDataForLessonAction, loadParticipationDataForLessonActionSuccess } from '../state/lesson.actions';
import { Store } from '@ngrx/store';
import { LessonCreateUpdate } from '../models/LessonCreateUpdate';
import { PractiseGroupCreate } from '../models/PractiseGroupCreate';
import { Participation } from '../models/Participation';
import { ParticipationUpdate } from '../models/ParticipationUpdate';
import { Observable, firstValueFrom } from 'rxjs';
import { Pagination } from '../../common/models/Pagination';

import { saveAs } from 'file-saver';

const URL_REQ_ALL_LESSON = environment.baseUrl + "v1/lessons?pageNumber=:pageNumber&pageSize=:pageSize";
const URL_ADD_LESSON = environment.baseUrl + "v1/lessons";
const URL_EDIT_LESSON = environment.baseUrl + "v1/lessons/:id";
const URL_REQ_LESSON_BY_ID = environment.baseUrl + "v1/lessons/:id";

const URL_ADD_TASK_TO_LESSON = environment.baseUrl + "v1/lessons/:lId/tasks";
const URL_DEL_TASK_FROM_LESSON = environment.baseUrl + "v1/lessons/:lId/tasks/:tId";

const URL_ADD_PRACTISEGROUP = environment.baseUrl + "v1/practise-groups";
const URL_DEL_PRACTISEGROUP = environment.baseUrl + "v1/practise-groups/:id";

const URL_ADD_PARTICIPANT_GROUP = environment.baseUrl + "v1/practise-groups/:gId/participants/:pId";
const URL_DEL_PARTICIPANT_GROUP = environment.baseUrl + "v1/practise-groups/:gId/participants/:pId";

const URL_ADD_INSTRUCTOR_GROUP = environment.baseUrl + "v1/practise-groups/:gId/instructors/:pId";
const URL_DEL_INSTRUCTOR_GROUP = environment.baseUrl + "v1/practise-groups/:gId/instructors/:pId";

const URL_GET_PARTICIPATION = environment.baseUrl + "v1/participation?lessonId=:lId";
const URL_EDIT_PARTICIPATION = environment.baseUrl + "v1/participation/:lId/:pId";


@Injectable({
  providedIn: 'root'
})
export class LessonService {
  donwloadGroupReport(groupId: string) {
    let token = localStorage.getItem("access_token") || "";
    let fileUrl = environment.baseUrl + "v1/practise-groups/" + groupId + "/report";
    console.log(fileUrl);

    this.http.get(
      fileUrl,
      {
        headers: new HttpHeaders().set("Authorization", "Bearer " + token),
        observe: "response",
        responseType: "blob"
      }
    ).subscribe({
      next: 
      response => {
        const file = response.body as Blob;
        if (!file)
          return;
        saveAs(file, response.headers.get("Content-Disposition")?.split("filename=")[1] ?? "Group report");
      },
      error: 
      error => {
        console.log(error);
      }
    });


  }

  constructor(private http: HttpClient, private store: Store) { }

  private getAllLessonsRequest(token: string, pageNumber: number, pageSize: number) {
    return this.http.get<Lesson[]>(
      URL_REQ_ALL_LESSON.replace(":pageNumber", pageNumber.toString()).replace(":pageSize", pageSize.toString()),
      {
        headers: new HttpHeaders().set("Authorization", "Bearer " + token),
        observe: "response"
      }
    );
  }

  private getLessonByIdRequest(token: string, id: string) {
    return this.http.get<Lesson>(
      URL_REQ_LESSON_BY_ID.replace(":id", id),
      {
        headers: new HttpHeaders().set("Authorization", "Bearer " + token)
      }
    );
  }

  private async getAllLessons() {
    let token = localStorage.getItem("access_token") || "";
    let pageSize = 10;
    let maxCountPages = 1;
    let init = false;

    let allLessons: Lesson[] = [];
    for (let i = 0; i < maxCountPages; i++) {
      let response = await firstValueFrom(this.getAllLessonsRequest(token, i + 1, pageSize));

      if (response.body != null) {
        allLessons = allLessons.concat(response.body);
      }

      if (!init) {
        init = true;
        let paginationRaw = response.headers.get("X-Pagination") ?? "";

        let pagination: Pagination = JSON.parse(paginationRaw);
        maxCountPages = pagination.amountPages;
      }
    }

    return allLessons;
  }

  private async getAllLessonById(id: string) {
    let token = localStorage.getItem("access_token") || "";
    try {
      let lesson = await firstValueFrom(this.getLessonByIdRequest(token, id));
      return lesson;
    } catch (error) {
      throw new Error("Lesson with id: " + id + " not found.");
    }
  }

  loadAllLessons(): Observable<Lesson[]> {
    return new Observable<Lesson[]>((subscriber) => {
      this.getAllLessons()
        .then(data => { subscriber.next(data); subscriber.complete(); })
        .catch(err => { subscriber.error("Fehler beim Laden der Übungsstundenübersicht."); subscriber.complete(); });
    });
  }

  loadSingleLessonWithDetails(id: string): Observable<Lesson> {
    return new Observable<Lesson>((subscriber) => {
      this.getAllLessonById(id)
        .then(data => { subscriber.next(data); subscriber.complete(); })
        .catch(err => { subscriber.error("Fehler beim Laden der Übungsstundenübersicht."); subscriber.complete(); });
    });
  }

  addLesson(lesson: LessonCreateUpdate) {
    let token = localStorage.getItem("access_token") || "";
    this.http.post<void>(
      URL_ADD_LESSON,
      lesson,
      {
        headers: new HttpHeaders().set("Authorization", "Bearer " + token)
      }
    ).subscribe(
      {
        next: () => {
          this.store.dispatch(loadLessonsAction());
        },
        error: (err) => {
          console.log(err)
          this.store.dispatch(loadLessonsFailAction({error: err}));
        }
      }
    );
  }

  updateLesson(id: string, lesson: LessonCreateUpdate) {
    let token = localStorage.getItem("access_token") || "";
    let url = URL_EDIT_LESSON.replace(":id", id);
    this.http.put<void>(
      url,
      lesson,
      {
        headers: new HttpHeaders().set("Authorization", "Bearer " + token)
      }
    ).subscribe(
      {
        next: () => {
          this.store.dispatch(loadLessonByIdAction({id: id}));
        },
        error: (err) => console.log(err)
      }
    );
  }

  addTaskToLesson(lId: string, tId: string) {
    let token = localStorage.getItem("access_token") || "";

    let url = URL_ADD_TASK_TO_LESSON.replace(":lId", lId);

    let data = [];
    data.push(tId);

    this.http.post<void>(
      url,
      data,
      {
        headers: new HttpHeaders().set("Authorization", "Bearer " + token)
      }
    ).subscribe(
      {
        next: (data) => {
          this.store.dispatch(loadLessonByIdAction({id: lId}));
        },
        error: (err) => {
          console.log("fehler");

          console.log(err);
        }
      }
    );
  }

  removeTaskFromLesson(lId: string, tId: string) {
    let token = localStorage.getItem("access_token") || "";

    let url = URL_DEL_TASK_FROM_LESSON.replace(":lId", lId);
    url = url.replace(":tId", tId);


    this.http.delete<void>(
      url,
      {
        headers: new HttpHeaders().set("Authorization", "Bearer " + token)
      }
    ).subscribe(
      {
        next: () => {
          this.store.dispatch(loadLessonByIdAction({id: lId}));
        },
        error: (err) => console.log(err)
      }
    );
  }

  addPractiseGroup(group: PractiseGroupCreate) {
    let token = localStorage.getItem("access_token") || "";

    let url = URL_ADD_PRACTISEGROUP;

    this.http.post<void>(
      url,
      group,
      {
        headers: new HttpHeaders().set("Authorization", "Bearer " + token)
      }
    ).subscribe(
      {
        next: () => {
          this.store.dispatch(loadLessonByIdAction({id: group.lessonId ?? ""}));
        },
        error: (err) => console.log(err)
      }
    );
  }

  removePractiseGroup(id: string, lId: string) {
    let token = localStorage.getItem("access_token") || "";

    let url = URL_DEL_PRACTISEGROUP.replace(":id", id);

    this.http.delete<void>(
      url,
      {
        headers: new HttpHeaders().set("Authorization", "Bearer " + token)
      }
    ).subscribe(
      {
        next: () => {
          this.store.dispatch(loadLessonByIdAction({id: lId}));
          
        },
        error: (err) => console.log(err)
      }
    );
  }

  addPartcipantToGroup(pId: string, gId: string, lId: string) {
    let token = localStorage.getItem("access_token") || "";

    let url = URL_ADD_PARTICIPANT_GROUP.replace(":pId", pId);
    url = url.replace(":gId", gId);

    this.http.post<void>(
      url,
      {},
      {
        headers: new HttpHeaders().set("Authorization", "Bearer " + token)
      }
    ).subscribe(
      {
        next: () => {
          this.store.dispatch(loadLessonByIdAction({id: lId}));
          
        },
        error: (err) => console.log(err)
      }
    );
  }

  removeParticipantFromGroup(pId: string, gId: string, lId: string) {
    let token = localStorage.getItem("access_token") || "";

    let url = URL_DEL_PARTICIPANT_GROUP.replace(":pId", pId);
    url = url.replace(":gId", gId);

    this.http.delete<void>(
      url,
      {
        headers: new HttpHeaders().set("Authorization", "Bearer " + token)
      }
    ).subscribe(
      {
        next: () => {
          this.store.dispatch(loadLessonByIdAction({id: lId}));
        },
        error: (err) => console.log(err)
      }
    );
  }

  addInstructorToGroup(pId: string, gId: string, lId: string) {
    let token = localStorage.getItem("access_token") || "";

    let url = URL_ADD_INSTRUCTOR_GROUP.replace(":pId", pId);
    url = url.replace(":gId", gId);

    this.http.post<void>(
      url,
      {},
      {
        headers: new HttpHeaders().set("Authorization", "Bearer " + token)
      }
    ).subscribe(
      {
        next: () => {
          this.store.dispatch(loadLessonByIdAction({id: lId}));
        },
        error: (err) => console.log(err)
      }
    );
  }

  removeInstructorFromGroup(pId: string, gId: string, lId: string) {
    let token = localStorage.getItem("access_token") || "";

    let url = URL_DEL_INSTRUCTOR_GROUP.replace(":pId", pId);
    url = url.replace(":gId", gId);

    this.http.delete<void>(
      url,
      {
        headers: new HttpHeaders().set("Authorization", "Bearer " + token)
      }
    ).subscribe(
      {
        next: () => {
          this.store.dispatch(loadLessonByIdAction({id: lId}));
        },
        error: (err) => console.log(err)
      }
    );
  }

  updateParticipationForLessonForPerson(pId: string, lId: string, data: ParticipationUpdate) {
    let token = localStorage.getItem("access_token") || "";

    let url = URL_EDIT_PARTICIPATION.replace(":pId", pId);
    url = url.replace(":lId", lId);

    this.http.put<void>(
      url,
      data,
      {
        headers: new HttpHeaders().set("Authorization", "Bearer " + token)
      }
    ).subscribe(
      {
        next: () => {
          this.store.dispatch(loadParticipationDataForLessonAction({ lId }));
        },
        error: (err) => console.log(err)
      }
    );
  }

  loadParticipationData(lId: string) {
    let token = localStorage.getItem("access_token") || "";

    let url = URL_GET_PARTICIPATION.replace(":lId", lId);

    this.http.get<Participation[]>(
      url,
      {
        headers: new HttpHeaders().set("Authorization", "Bearer " + token)
      }
    ).subscribe(
      {
        next: (data) => {
          this.store.dispatch(loadParticipationDataForLessonActionSuccess({ lId: lId, data: data }));
        },
        error: (err) => console.log(err)
      }
    );
  }

}
