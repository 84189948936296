import { Component, Inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable, map, of } from 'rxjs';
import { PractiseGroup } from '../../models/PractiseGroup';
import { DropDownEntry } from 'src/app/modules/basic-controls/models/DropDownEntry';

@Component({
  selector: 'app-select-practise-group',
  templateUrl: './select-practise-group.component.html',
  styleUrls: ['./select-practise-group.component.scss']
})
export class SelectPractiseGroupComponent {
  formular = this.fb.group({
    group: ["", [Validators.required]]
  });

  entries$ : Observable<DropDownEntry[]> = this.data.availableGroups$.pipe(
    map(grps => 
      grps.map(g => {
        let instructor = g.instructors.length !== 0 
          ? g.instructors[0].name + ", " + g.instructors[0].prename 
          : "";
        let label = g.groupName + "(" + instructor +")";
        return {key: g.id, label: label};
      })
    )
  );

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {availableGroups$: Observable<PractiseGroup[]>},
    public dialogRef : MatDialogRef<SelectPractiseGroupComponent>, 
    private fb: FormBuilder) { }

  close(canceled: boolean) {
    if (canceled){
      this.dialogRef.close();
    } else {
      this.dialogRef.close(this.formular.value.group);
    }
  }
}
