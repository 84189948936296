<div class="container">
    <h2>Übungsstunden</h2>
    <div class="nav-elements">
        <div class="card" routerLink="/lessons/list">
            <h3><i class="fa-solid fa-list"></i>Alle Übungsstunden</h3>
            <p class="helper">Übersicht aller Übungsstunden</p>
            <div class="link">
                <i class="fa-solid fa-arrow-up-right-from-square"></i>
            </div>
        </div>
        <div *ngIf="(currentLesson$ | async) !== undefined" class="card" [routerLink]="['/lessons', (currentLesson$ | async)?.id ,'preparation']">
            <h3><i class="fa-solid fa-clock"></i>Vorbereitung</h3>
            <p class="helper">Hier können Übungsstunden vorbereitet werden</p>
            <div class="link">
                <i class="fa-solid fa-arrow-up-right-from-square"></i>
            </div>
        </div>
        <div *ngIf="(currentLesson$ | async) !== undefined" class="card" [routerLink]="['/lessons', (currentLesson$ | async)?.id ,'execution']">
            <h3><i class="fa-solid fa-spinner"></i>Durchführung</h3>
            <p class="helper">Hier können die Übungsstunden durchgeführt werden.</p>
            <div class="link">
                <i class="fa-solid fa-arrow-up-right-from-square"></i>
            </div>
        </div>
        <div *ngIf="(currentLesson$ | async) !== undefined" class="card" [routerLink]="['/lessons', (currentLesson$ | async)?.id ,'reworking']">
            <h3><i class="fa-regular fa-circle-check"></i>Nachbereitung</h3>
            <p class="helper">Hier können die Übungsstunden nachdokumentiert werden.</p>
            <div class="link">
                <i class="fa-solid fa-arrow-up-right-from-square"></i>
            </div>
        </div>
    </div>
</div>