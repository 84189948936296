<div class="container">
    <app-accordion class="general" title="Generelles" [collapsed]="true">
        <div class="entry">
            <h4>Datum</h4>
            <p>{{formatDate((lesson$ | async)?.eventDate)}}</p>
        </div>
        <div class="entry">
            <h4>Wer hält die Andacht</h4>
            <p>{{(lesson$ | async)?.impulseHeldBy?.name}}, {{(lesson$ | async)?.impulseHeldBy?.prename}}
            </p>
        </div>
        <div class="entry">
            <h4>Thema der Andacht</h4>
            <p>{{(lesson$ | async)?.impulseTopic}}</p>
        </div>
        <button type="button" class="btn-secondary btn-label" [routerLink]="['/lessons', lessonId, 'edit']"><i
                class="fa-solid fa-pen"></i>Stunde bearbeiten</button>
    </app-accordion>
</div>

