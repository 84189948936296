<div class="container">
    <h2>Übungsstunden</h2>
    <div class="toolbar">
        <button type="button" class="btn-primary btn-add" routerLink="/lessons/add"><i class="fa-solid fa-plus"></i>Hinzufügen</button>
    </div>
    <div class="data">
        <div class="data-header">
            <h3 class="col">
                Datum
            </h3>
            <h3></h3>
        </div>
        <div *ngFor="let lesson of this.lessons$ | async" class="data-entry">
            <div class="col field-data">
                <p>Stunde vom {{formatLessonHeading(lesson.eventDate)}}</p>
             </div>
            <div class="col action">
                <button type="button" class="btn-primary btn-add"
                    [routerLink]="['/lessons', lesson.id, 'details']">
                    <i class="fa-solid fa-info"></i>
                </button>
            </div>
        </div>
    </div>
</div>
