import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import * as AuthenticationActions from "./registration.action";
import { tap } from "rxjs";
import { UserService } from "../services/user.service";

@Injectable()
export class AuthenticationEffects {
    registerEffect = createEffect(
        () => {
            return this.actions$.pipe(ofType(AuthenticationActions.registerAction), tap(data => this.userService.register(data)));
        }, 
        {
            dispatch: false
        }
    );

    loginEffect = createEffect(
        () => {
            return this.actions$.pipe(ofType(AuthenticationActions.loginAction), tap(data => this.userService.login(data)));
        },
        {
            dispatch: false
        }
    );

    logoutEffect = createEffect(
        () => {
            return this.actions$.pipe(ofType(AuthenticationActions.logoutAction), tap(() => this.userService.logout()))
        },
        {
            dispatch: false
        }
    );

    requestLoginEffect = createEffect(
        () => {
            return this.actions$.pipe(ofType(AuthenticationActions.requestLoginAction), tap(() => this.userService.getAllUsers()))
        },
        {
            dispatch: false
        }
    );

    activateLoginEffect = createEffect(
        () => {
            return this.actions$.pipe(ofType(AuthenticationActions.activateLoginAction), tap((data) => this.userService.activateUser(data.userId)));
        },
        {
            dispatch: false
        }
    );

    deactivateLoginEffect = createEffect(
        () => {
            return this.actions$.pipe(ofType(AuthenticationActions.deactivateLoginAction), tap((data) => this.userService.deactivateUser(data.userId)));
        },
        {
            dispatch: false
        }
    );

    updateUserRightsEffect = createEffect(
        () => {
            return this.actions$.pipe(ofType(AuthenticationActions.changeUserRights), tap((data) => this.userService.changeUserRights(data.userId, data.rights)));
        },
        {
            dispatch: false
        }
    );

    
    constructor(private actions$: Actions, private userService : UserService){

    }
}