import { Injectable } from '@angular/core';
import { Message } from '../models/Message';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  currentMessage$ = new BehaviorSubject<Message[] | []>([]);

  messages: Message[] = [];

  constructor() {
  }
  
  addMessage(message: Message){
    this.messages.push(message);
    this.currentMessage$.next(this.messages);
  }

  closeCurrentMessage(){
    this.messages.shift();
    this.currentMessage$.next(this.messages);
  }
}
