import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import * as InstrumentsActions from "./instruments.actions";
import { tap } from "rxjs";
import { InstrumentsService } from "../services/instruments.service";

@Injectable()
export class InstrumentsEffects {

    requestInstrumentsEffect = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(InstrumentsActions.loadInstrumentsAction), 
                tap( () => this.instrumentService.loadAllInstruments())
            );
        },
        {
            dispatch: false
        }
    );

    addInstrumentEffect = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(InstrumentsActions.addInstrumentAction),
                tap(instrument => this.instrumentService.addInstrument(instrument))
            );
        },
        {
            dispatch: false
        }
    );

    editInstrumentEffect = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(InstrumentsActions.editInstrumentAction),
                tap((data) => this.instrumentService.editInstrument(data.id, data.data))
            );
        },
        {
            dispatch: false
        }
    );

    deleteInstrumentEffect = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(InstrumentsActions.deleteInstrumentAction),
                tap((data) => this.instrumentService.deleteInstrument(data.id))
            );
        },
        {
            dispatch: false
        }
    );
    constructor(private actions$: Actions, private instrumentService : InstrumentsService){

    }
}