import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { ToastService } from '../../toaster/services/toast.service';

import { environment } from 'src/environments/environment';
import { InstrumentCreate } from '../models/InstrumentCreate';
import { Instrument } from '../models/Instrument';
import { loadInstrumentsAction, loadInstrumentsSuccessAction } from '../state/instruments.actions';


const REQ_ALL_INSTR_URL = environment.baseUrl + "v1/instruments";

const ADD_INSTR_URL = environment.baseUrl + "v1/instruments";
const EDIT_INSTR_URL = environment.baseUrl + "v1/instruments/:id";
const DEL_INSTR_URL = environment.baseUrl + "v1/instruments/:id";

@Injectable({
  providedIn: 'root'
})
export class InstrumentsService {

  constructor(private httpClient: HttpClient,  private store: Store, private router: Router, private toastService: ToastService) { }

  loadAllInstruments() {
    let token = localStorage.getItem("access_token") || "";
    this.httpClient.get<Instrument[]>(REQ_ALL_INSTR_URL,
      {
        headers: new HttpHeaders().set("Authorization", "Bearer " + token)
      }).subscribe({
        next: (data: Instrument[]) => {
          this.store.dispatch(loadInstrumentsSuccessAction({data: data}));
        },
        error: (err) => console.log(err)
      }
    );
  }

  addInstrument(instrument: InstrumentCreate) {
    let token = localStorage.getItem("access_token") || "";
    this.httpClient.post(ADD_INSTR_URL,
      instrument,
      {
        headers: new HttpHeaders().set("Authorization", "Bearer " + token)
      }).subscribe({
        next: () => {
          this.store.dispatch(loadInstrumentsAction());
        },
        error: (err) => console.log(err)
      }
    );
  }

  editInstrument(id: string, instrument: Instrument) {
    let token = localStorage.getItem("access_token") || "";
    this.httpClient.put(
      EDIT_INSTR_URL.replace(":id", id),
      instrument,
      {
        headers: new HttpHeaders().set("Authorization", "Bearer " + token)
      }).subscribe({
        next: () => {
          this.store.dispatch(loadInstrumentsAction());
        },
        error: (err) => console.log(err)
      }
    );
  }

  deleteInstrument(id: string) {
    let token = localStorage.getItem("access_token") || "";
    this.httpClient.delete(DEL_INSTR_URL.replace(":id", id),
      {
        headers: new HttpHeaders().set("Authorization", "Bearer " + token)
      }).subscribe({
        next: () => {
          this.store.dispatch(loadInstrumentsAction());
        },
        error: (err) => console.log(err)
      }
    );
  } 
}
