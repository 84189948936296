import { createAction, props } from "@ngrx/store";
import { Task } from "../models/Task";
import { TaskCreate } from "../models/TaskCreate";
import { TaskUpdate } from "../models/TaskUpdate";
import { TaskProgress } from "../models/TaskProgress";

export const loadTasksAction = 
    createAction('[Task handling] Load tasks');

export const loadTasksSuccessAction = 
    createAction('[Task handling] Load tasks success', props<{data: Task[]}>());

export const loadOpenTasksAction = 
    createAction('[Task handling] Load only open tasks');

export const loadOpenTasksSuccessAction = 
    createAction('[Task handling] Load only open tasks success', props<{data: Task[]}>());

export const addTaskAction = 
    createAction('[Task handling] Add task', props<TaskCreate>());

export const editTaskAction = 
    createAction('[Task handling] Edit task', props<{id: string, data: TaskUpdate}>());

export const deleteTaskAction = 
    createAction('[Task handling] Delete task', props<{id: string}>());

export const updateTaskState = 
    createAction('[Task handling] Update task progres', props<{data: TaskProgress, lId: string}>());