import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectUserRights } from '../modules/authentication/state/authorization.selector';
import { map } from 'rxjs';
import { CONFIG } from '../configuration';
import { logoutAction } from '../modules/authentication/state/registration.action';


@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {

  menuActive = false;

  userLoggedIn$ = this.store.select(selectUserRights).pipe(map(data => data.findIndex(r => r.id === CONFIG.BASIC_RIGHT_ID) !== -1));
  adminUserLoggedIn$ = this.store.select(selectUserRights).pipe(map(data => data.findIndex(r => r.id === CONFIG.ADMIN_RIGHT_ID) !== -1));

  constructor(private store: Store) {
    
   }

  ngOnInit(): void {
  }

  toggleMenu() {
    this.menuActive = !this.menuActive;
  }

  logout() {
    this.menuActive = false;
    this.store.dispatch(logoutAction());
  }

}
