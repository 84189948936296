import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { loadCommentsAction } from 'src/app/modules/comments/state/comment.action';
import { loadPersonsAction } from 'src/app/modules/persons/state/persons.actions';
import { availableInstructors, availableParticipants } from 'src/app/modules/persons/state/persons.selector';
import { loadTasksAction, loadOpenTasksAction } from 'src/app/modules/task-handling/state/task-handling.actions';
import { loadLessonByIdAction, loadLessonsAction, loadParticipationDataForLessonAction, updateParticipationLessonPersonAction } from '../../state/lesson.actions';
import { getGroupForLessonByPerson, getLessonById, getParticipationStateOfPersonById } from '../../state/lesson.selector';
import { Observable } from 'rxjs';
import { Lesson } from '../../models/Lesson';

@Component({
  selector: 'app-lesson-execution',
  templateUrl: './lesson-execution.component.html',
  styleUrls: ['./lesson-execution.component.scss']
})
export class LessonExecutionComponent {
  currentId = "";

  currentLesson$ : Observable<Lesson>;

  instructorsRaw$ = this.store.select(availableInstructors);

  participants$ = this.store.select(availableParticipants);

  
  constructor(private fb: FormBuilder, private activeRoute: ActivatedRoute, private store: Store, private dialog: MatDialog) {
    this.currentId = this.activeRoute.snapshot.paramMap.get("id") as string;

    this.store.dispatch(loadLessonByIdAction({id: this.currentId}));
    this.store.dispatch(loadTasksAction());
    this.store.dispatch(loadOpenTasksAction());
    this.store.dispatch(loadCommentsAction());
    this.store.dispatch(loadPersonsAction());
    this.store.dispatch(loadParticipationDataForLessonAction({lId: this.currentId}));
    
    this.currentLesson$ = this.store.select(getLessonById(this.currentId)).pipe();
  }

  getPersonState(pId: string) {
    return this.store.select(getParticipationStateOfPersonById(pId, this.currentId)).pipe();
  }

  getGroupForPerson(pId: string) {
    return this.store.select(getGroupForLessonByPerson(pId, this.currentId)).pipe();
  }

  updatePersonState(pId: string, state: number, reason: string | null) {
    this.store.dispatch(updateParticipationLessonPersonAction({pId: pId, lId: this.currentId, data: {
      reason: reason,
      state: state
    }}))
  }
}
