import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { loginAction } from '../../state/registration.action';
import { UserLogin } from '../../models/UserLogin';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {

  loginForm  = this.fb.group({
    email: ["", [Validators.required, Validators.email]],
    password: ["", [Validators.required, Validators.minLength(8)]]
  });

  constructor(private fb: FormBuilder, private store: Store) {


  }

  login(){
    let user: UserLogin = {
      email: this.loginForm.controls.email.value || "",
      password: this.loginForm.controls.password.value || ""
    }
    this.store.dispatch(loginAction(user));
  }
}
