import { Person } from "../../persons/models/Person";
import { Task } from "../../task-handling/models/Task";
import { TaskRaw } from "../../task-handling/models/TaskRaw";
import { Participation } from "./Participation";
import { PractiseGroup } from "./PractiseGroup";


export class Lesson {
    id = "";
    eventDate: Date | string = new Date();
    impulseHeldBy: Person = new Person(); // id, name, prename
    impulseTopic = "";
    groups: PractiseGroup[] = []; // group service
    participation: Participation[] = []; // participation service
    tasks: TaskRaw[] = [];
    openTasksBeforeStart: Task[] = [];
}