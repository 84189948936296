import { Input, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputFieldComponent } from './components/input-field/input-field.component';
import { ReactiveFormsModule } from '@angular/forms';
import { DropDownComponent } from './components/drop-down/drop-down.component';
import { AccordionComponent } from './components/accordion/accordion.component';



@NgModule({
  declarations: [
    InputFieldComponent,
    DropDownComponent,
    AccordionComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule
  ],
  exports: [
    InputFieldComponent,
    DropDownComponent,
    AccordionComponent
  ]
})
export class BasicControlsModule { }
