import { createAction, props } from "@ngrx/store";
import { Comment } from "../models/Comment";
import { CommentCreate } from "../models/CommentCreate";
import { CommentUpdate } from "../models/CommentUpdate";

export const loadCommentsAction = 
    createAction('[Comments] Load comments');

export const loadCommentsSuccessAction = 
    createAction('[Comments] Load comments success', props<{data: Comment[]}>());


export const addCommentAction =
    createAction('[Comments] Add comment to practise group', props<{data: CommentCreate}>());

export const updateCommentAction =
    createAction('[Comments] Update comment to practise group', props<{id: string, data: CommentUpdate, lId: string}>());

export const removeCommentAction =
    createAction('[Comments] Remove comment from practise group', props<{id: string, lId: string}>());
