<div class="container">
    <h2>Übungsstunde</h2>
    <div class="toolbar">
        <button [routerLink]="['/lessons', currentLesson?.id, 'preparation']" type="button" class="btn-primary btn-add"><i class="fa-solid fa-clock"></i>Vorbereiten</button>
        <button [routerLink]="['/lessons', currentLesson?.id, 'execution']" type="button" class="btn-primary btn-add"><i class="fa-solid fa-spinner"></i>Durchführen</button>
        <button [routerLink]="['/lessons', currentLesson?.id, 'reworking']" type="button" class="btn-primary btn-add"><i class="fa-regular fa-circle-check"></i>Nachbereiten</button>
    </div>
    <div class="data-group">
        <h3>Datum</h3>
        <div class="data-row">
            <p>{{formatDate(currentLesson?.eventDate)}}</p>
        </div>
    </div>
    <div class="data-group">
        <h3>Andacht</h3>
        <div class="data-row">
            <p>{{currentLesson?.impulseHeldBy?.prename}} {{currentLesson?.impulseHeldBy?.name}}</p>
            <p>{{currentLesson?.impulseTopic}}</p>
        </div>
    </div>
    <div class="data-group">
        <h3>Tägliche Übungen</h3>
        <div class="data-row">
            <ul>
                <li *ngFor="let t of dailyTasks">
                    {{t.title}} (Kap.:{{t.chapter }} Nr.: {{t.number}})
                </li>
            </ul>
        </div>
    </div>
    <div class="data-group">
        <h3>Übungsgruppen</h3>
        <div *ngFor="let g of groups; let idx = index" class="data-subgroup">
            <h4>{{g.groupName}}</h4>
            <div class="data-section">
                <h5>Kommentare ({{g.comments.length}})</h5>
                <ul>
                    <li *ngFor="let c of g.comments">
                        <span class="helper">{{c.name}}, {{c.prename}}:</span>&nbsp;<span class="content">{{c.content}}</span>
                    </li>
                </ul>
            </div>
            <div class="data-section">
                <h5>Anleiter ({{g.instructors.length}})</h5>
                <ul>
                    <li *ngFor="let i of g.instructors">
                        {{i.name}}, {{i.prename}}
                    </li>
                </ul>
            </div>
            <div class="data-section">
                <ul>
                    <h5>Jungbläser ({{g.participants.length}})</h5>
                    <li *ngFor="let p of g.participants">
                        {{p.name}}, {{p.prename}}
                    </li>
                </ul>
            </div>
            <button (click)="startDownload(g.id)" type="button" class="btn-primary btn-download"><i class="fa-solid fa-file-pdf"></i></button>
        </div>
    </div>
    <div class="data-group">
        <h3>Abwesende Personen</h3>
        <div class="data-row">
            <ul>
                <li *ngFor="let p of absentPerson">
                    {{getPersonNameById(p.personId)}}
                </li>
            </ul>
        </div>
    </div>
</div>
