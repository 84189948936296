import { createAction, props } from "@ngrx/store";
import { UserCreate } from "../models/UserCreate";
import { UserLogin } from "../models/UserLogin";
import { User } from "../models/User";

export const registerAction = createAction('[Authentication] Register', props<UserCreate>());

export const loginAction = createAction('[Authentication] Login', props<UserLogin>());
export const loginSuccessAction = createAction('[Authentication] Login success', props<{user: User}>());
export const refreshSuccessAction = createAction('[Authentication] Refresh success', props<{user: User}>());

export const logoutAction = createAction('[Authentication] Logout');

export const requestLoginAction = createAction('[Authentication] Get all logins');
export const getLoginsAction = createAction('[Authentication] Get all logins success', props<{users: User[]}>());

export const activateLoginAction = createAction('[Authentication] Activate user', props<{userId: string}>());
export const deactivateLoginAction = createAction('[Authentication] Deactivate user', props<{userId: string}>());

export const changeUserRights = createAction('[Authentication] Change user rights', props<{userId: string, rights: string[]}>());