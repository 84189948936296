import { createFeatureSelector, createSelector } from "@ngrx/store";
import { getLessonById, lessonStateWithTasks, lessonsState } from "../../lessons/state/lesson.selector";
import { allOpenTasks } from "../../task-handling/state/task-handling.selector";

import * as fromComments from "./comment.reducer";
import { personsState } from "../../persons/state/persons.selector";
import { Comment, CommentDetail } from "../models/Comment";


export const commentsState = createFeatureSelector<fromComments.State>(
    fromComments.commentsFeatureKey
);

export const commentsForPerson = (personId: string, amount: number) => createSelector(
    commentsState,
    lessonsState,
    (comments, lessons) => {
        let commentsDetail : CommentDetail[] = [];
        comments.comments.filter(c => {
            return c.personId === personId;
        }).forEach( c => {
            commentsDetail.push({
                ... c,
                lessonDate: lessons.lessons.find(l => l.id === c.lessonId)?.eventDate
            })
        });

        return commentsDetail.slice(0, amount);
    }
);

export const getCommentForLesson = (lessonId: string, amount: number) => createSelector(
    commentsState,
    (rawComments) => {
        let comments: Comment[] = [];

        rawComments.comments
            .filter( c => c.lessonId === lessonId && c.personId === null && c.groupId === null)
            .forEach(c => comments.push(c));
        return comments.slice(0, amount);
    }
);

export const getCommentForGroup = (groupId: string, amount: number) => createSelector(
    commentsState,
    (rawComments) => {
        let comments: Comment[] = [];

        rawComments.comments
            .filter( c => c.groupId === groupId && c.personId === null)
            .forEach(c => comments.push(c));
        return comments.slice(0, amount);
    }
);

export const getCommentForPersonAndLesson = (lessonId: string, personId: string, amount: number) => createSelector(
    commentsState,
    (rawComments) => {
        let comments: Comment[] = [];

        rawComments.comments
            .filter( c => c.lessonId === lessonId && c.personId === personId)
            .forEach(c => comments.push(c));
        return comments.slice(0, amount);
    }
);

