import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OverviewComponent } from './views/overview/overview.component';
import { AdminGuard } from '../authentication/guards/admin.guard';
import { AdminPageComponent } from '../authentication/views/admin-page/admin-page.component';
import { InstumentsAdministrationComponent } from '../instruments/views/instuments-administration/instuments-administration.component';
import { TaskListComponent } from '../task-handling/views/task-list/task-list.component';

const routes: Routes = [
  {
    path: "admin",
    canActivate: [AdminGuard],
    children: [
      {
        path: "",
        component: OverviewComponent
      },
      {
        path: "logins",
        component: AdminPageComponent
      },
      {
        path: "instruments",
        component: InstumentsAdministrationComponent
      },
      {
        path: "tasks",
        component: TaskListComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdministrationRoutingModule { }
