<div class="container">
    <h2>Übungsstunde durchführen</h2>
    <!-- General information -->
    <app-general-card [lesson$]="currentLesson$" [lessonId]="currentId"></app-general-card>
    <app-accordion *ngFor="let partitipant of (participants$ | async)"
        [errorColor]="false"
        [actionRequired]="(getPersonState(partitipant.id) | async) == 0"
        [errorColor]="(getPersonState(partitipant.id) | async) == 2"
        [collapsed]="(getPersonState(partitipant.id) | async) === 1 || (getPersonState(partitipant.id) | async) === 2"
        [subtitle]="partitipant.instrument.name"
        [title]="partitipant.name + ', ' + partitipant.prename">

        <div class="group-information-participant">
            <h4>
                Gruppe
            </h4>
            <p class="helper">{{(getGroupForPerson(partitipant.id) | async)?.groupName}}</p>
            <p class="helper" *ngFor="let instructor of (getGroupForPerson(partitipant.id) | async)?.instructors">{{instructor.name}}, {{instructor.prename}}</p>
            <div class="action">
                <button 
                    [disabled]="(getPersonState(partitipant.id) | async) === 1" 
                    (click)="updatePersonState(partitipant.id, 1, null)"
                    type="button" 
                    class="btn-ok btn-label mg-btm"
                ><i
                    class="fa-solid fa-check"></i>Anwesend</button>
                <button [disabled]="(getPersonState(partitipant.id) | async) === 2" type="button mg-btm"
                        (click)="updatePersonState(partitipant.id, 2, null)"
                        class="btn-err btn-label"><i
                        class="fa-solid fa-xmark"></i>Abwesend</button>
            </div>
        </div>
    </app-accordion>

    <app-accordion *ngFor="let instructor of instructorsRaw$ | async"
        [errorColor]="false"
        [actionRequired]="(getPersonState(instructor.id) | async) == 0"
        [errorColor]="(getPersonState(instructor.id) | async) == 2"
        [collapsed]="(getPersonState(instructor.id) | async) === 1 || (getPersonState(instructor.id) | async) === 2"
        [title]="instructor.name + ', ' + instructor.prename"
        >

        <div class="group-information-instructor">
            <h4>
                Gruppe
            </h4>
            <p class="helper">{{(getGroupForPerson(instructor.id) | async)?.groupName}}</p>
            <div class="action">
                <button 
                    [disabled]="(getPersonState(instructor.id) | async) === 1" 
                    (click)="updatePersonState(instructor.id, 1, null)"
                    type="button" 
                    class="btn-ok btn-label mg-btm"
                ><i
                    class="fa-solid fa-check"></i>Anwesend</button>
                <button [disabled]="(getPersonState(instructor.id) | async) === 2" type="button mg-btm"
                        (click)="updatePersonState(instructor.id, 2, null)"
                        class="btn-err btn-label"><i
                        class="fa-solid fa-xmark"></i>Abwesend</button>
            </div>
        </div>
    </app-accordion>
</div>