import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LessonsRoutingModule } from './lessons-routing.module';
import { OverviewComponent } from './views/overview/overview.component';
import { LessonsListComponent } from './views/lessons-list/lessons-list.component';
import { LessonPreparationComponent } from './views/lesson-preparation/lesson-preparation.component';
import { LessonExecutionComponent } from './views/lesson-execution/lesson-execution.component';
import { LessonReworkingComponent } from './views/lesson-reworking/lesson-reworking.component';
import { lessonsFeatureKey, reducer } from './state/lesson.reducer';
import { LessonsEffects } from './state/lesson.effects';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { LessonDetailsComponent } from './views/lesson-details/lesson-details.component';
import { LessonAddComponent } from './views/lesson-add/lesson-add.component';
import { ReactiveFormsModule } from '@angular/forms';
import { BasicControlsModule } from '../basic-controls/basic-controls.module';
import { LessonEditComponent } from './views/lesson-edit/lesson-edit.component';
import {MatDialogModule} from '@angular/material/dialog';
import { AddTaskToLessonComponent } from './components/add-task-to-lesson/add-task-to-lesson.component';
import { AddPractiseGroupComponent } from './components/add-practise-group/add-practise-group.component';
import { CommentsModule } from '../comments/comments.module';
import { GeneralCardComponent } from './components/general-card/general-card.component';
import { CommentCardComponent } from '../comments/components/comment-card/comment-card.component';
import { SelectPractiseGroupComponent } from './components/select-practise-group/select-practise-group.component';


@NgModule({
  declarations: [
    OverviewComponent,
    LessonsListComponent,
    LessonPreparationComponent,
    LessonExecutionComponent,
    LessonReworkingComponent,
    LessonDetailsComponent,
    LessonAddComponent,
    LessonEditComponent,
    AddTaskToLessonComponent,
    AddPractiseGroupComponent,
    GeneralCardComponent,
    CommentCardComponent,
    SelectPractiseGroupComponent,
  ],
  imports: [
    CommonModule,
    LessonsRoutingModule,
    ReactiveFormsModule,
    BasicControlsModule,
    MatDialogModule,
    CommentsModule,
    StoreModule.forFeature(lessonsFeatureKey, reducer),
    EffectsModule.forFeature([
      LessonsEffects
    ])
  ]
})
export class LessonsModule { }
