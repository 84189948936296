import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss']
})
export class AccordionComponent {
  @Input() title = "Hallo Welt";
  @Input() subtitle = "";
  @Input() collapsed = false;
  @Input() actionRequired = false;
  @Input() errorColor = false;

  toggleState() {
    this.collapsed = !this.collapsed;
  }
}
