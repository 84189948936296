import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { UserCreate } from '../../models/UserCreate';
import { Store } from '@ngrx/store';

import { registerAction } from '../../state/registration.action';
import { CONFIG } from 'src/app/configuration';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent {
  
  registerForm  = this.fb.group({
    name: ["", [Validators.required]],
    prename: ["", [Validators.required]],
    email: ["", [Validators.required, Validators.email]],
    password: ["", [Validators.required, Validators.minLength(8)]],
    passwordRepeat: ["",[ Validators.required, Validators.minLength(8)]]
  });

  constructor(private fb: FormBuilder, private store: Store) {
    this.registerForm.controls.password.valueChanges.subscribe((value) => {
      this.checkPasswordMatch();
    });
    this.registerForm.controls.passwordRepeat.valueChanges.subscribe((value) => {
      this.checkPasswordMatch();
    });

  }

  checkPasswordMatch(){
    if (this.registerForm.controls.passwordRepeat.errors != null)
      return;

    if (this.registerForm.controls.password.value !== this.registerForm.controls.passwordRepeat.value)
      this.registerForm.controls.passwordRepeat.setErrors({notMatch: true});
    else
      this.registerForm.controls.passwordRepeat.setErrors(null);
  }

  sendRegistration(){
    let user = new UserCreate();
    user.name = this.registerForm.value.name || "";
    user.prename = this.registerForm.value.prename || "";
    user.email = this.registerForm.value.email || "";
    user.password = this.registerForm.value.password || "";
    user.rights = [CONFIG.BASIC_RIGHT_ID]; // Default-user
    user.choirId = CONFIG.CHOIR_ID;

    // Dispatch
    this.store.dispatch(registerAction(user));
  }
}
