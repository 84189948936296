import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { deletePersonAction, loadPersonsAction } from '../../state/persons.actions';
import { availableInstructors, availableParticipants } from '../../state/persons.selector';

@Component({
  selector: 'app-instructor-overview',
  templateUrl: './instructor-overview.component.html',
  styleUrls: ['./instructor-overview.component.scss']
})
export class InstructorOverviewComponent {
  persons$ = this.store.select(availableInstructors).pipe();

  constructor(private store: Store){
    this.store.dispatch(loadPersonsAction());
  }

  deletePerson(id: string) {
    this.store.dispatch(deletePersonAction({id: id}));
  }
}
