import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { InstrumentsEditComponent } from './views/instruments-edit/instruments-edit.component';
import { IntrumentsCreateComponent } from './views/intruments-create/intruments-create.component';
import { AdminGuard } from '../authentication/guards/admin.guard';

const routes: Routes = [
  {
    path: "admin/instruments/add",
    component: IntrumentsCreateComponent,
    canActivate: [AdminGuard]
  },
  {
    path: "admin/instruments/edit/:id",
    component: InstrumentsEditComponent,
    canActivate: [AdminGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class InstrumentsRoutingModule { }
