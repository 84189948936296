import { createReducer, on } from "@ngrx/store";
import { Person } from "../models/Person";

import * as PersonsActions from "./persons.actions";


export const personsFeatureKey = "persons";

export interface State {
    persons: Person[];
}

export const initalState: State = {
    persons: []
}

export const reducer = createReducer(
    initalState,
    on(PersonsActions.loadPersonsActionSuccess, (state, {data}) : State => {
        return {
            persons: data
        }
    })
);