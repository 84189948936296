import { TaskProgress } from "./TaskProgress";


export class Task {
    id: string = "";
    title: string = "";
    series: number = 0;
    chapter: number = 0;
    number: number = 0;
    isActive: boolean = false;
    progress: TaskProgress[] = [];
};