import { NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavigationComponent } from './navigation/navigation.component';
import { HomeComponent } from './modules/home/home.component';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { AuthenticationModule } from './modules/authentication/authentication.module';
import { AuthenticationRoutingModule } from './modules/authentication/authentication-routing.module';

import { ReactiveFormsModule } from '@angular/forms';
import { ToasterModule } from './modules/toaster/toaster.module';
import { InstrumentsModule } from './modules/instruments/instruments.module';
import { InstrumentsRoutingModule } from './modules/instruments/instruments-routing.module';
import { AdministrationRoutingModule } from './modules/administration/administration-routing.module';
import { AdministrationModule } from './modules/administration/administration.module';
import { PersonsModule } from './modules/persons/persons.module';
import { PersonsRoutingModule } from './modules/persons/persons-routing.module';
import { TaskHandlingModule } from './modules/task-handling/task-handling.module';
import { TaskHandlingRoutingModule } from './modules/task-handling/task-handling-routing.module';
import { LessonsModule } from './modules/lessons/lessons.module';
import { LessonsRoutingModule } from './modules/lessons/lessons-routing.module';
import { BasicControlsModule } from './modules/basic-controls/basic-controls.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';


@NgModule({
  declarations: [
    AppComponent,
    NavigationComponent,
    HomeComponent,
  ],
  imports: [
    BrowserModule,
    BasicControlsModule,
    AppRoutingModule,
    AuthenticationModule,
    AuthenticationRoutingModule,
    InstrumentsModule,
    InstrumentsRoutingModule,
    TaskHandlingModule,
    TaskHandlingRoutingModule,
    AdministrationModule,
    AdministrationRoutingModule,
    LessonsModule,
    LessonsRoutingModule,
    PersonsModule,
    PersonsRoutingModule,
    ReactiveFormsModule,
    ToasterModule,
    StoreModule.forRoot(),
    EffectsModule.forRoot([]),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: !isDevMode() }),
    BrowserAnimationsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
