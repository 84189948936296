import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { first } from 'rxjs/operators'
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Instrument } from '../../models/Instrument';
import { editInstrumentAction } from '../../state/instruments.actions';
import { availableInstruments, getInstrumentById } from '../../state/instruments.selector';

@Component({
  selector: 'app-instruments-edit',
  templateUrl: './instruments-edit.component.html',
  styleUrls: ['./instruments-edit.component.scss']
})
export class InstrumentsEditComponent implements OnInit {
  instrumentForm = this.fb.group({
    name: ["", [Validators.required]]
  });

  currentId = "";

  constructor(private fb: FormBuilder, private store: Store, private router: Router, private activeRoute: ActivatedRoute){
    this.currentId = this.activeRoute.snapshot.paramMap.get("id") as string;
  }

  ngOnInit(): void {
    this.store.select(getInstrumentById(this.currentId))
    .pipe()
    .subscribe({
      next: (value) => {
        this.instrumentForm.setValue({name: value.name});
      }
    });
  }

  updateInstrument() {
    let instrument = new Instrument();
    instrument.id = this.currentId;
    instrument.name = this.instrumentForm.value.name || "---";

    // Dispatch
    this.store.dispatch(editInstrumentAction({id: this.currentId, data: instrument}));

    this.router.navigateByUrl("/admin/instruments");
  }
}
