<div class="container">
    <app-accordion class="general" [title]="title" [collapsed]="!opend">
        <ul class="comments">
                <li *ngFor="let comment of comments$ | async">
                    <p><span class="helper">{{comment?.creatorName}}, {{comment?.creatorPrename}}:</span>&nbsp;<span
                            class="content">{{comment?.content}}</span></p>
                    <button  type="button"
                        (click)="editComment(comment.id, comment.content, lessonId ?? '')"
                        class="btn-primary btn-ico">
                        <i class="fa-solid fa-pen"></i>
                    </button>
                    <button  type="button"
                        (click)="deleteComment(comment.id, lessonId ?? '')"
                        class="btn-secondary btn-add btn-ico">
                        <i class="fa-solid fa-trash"></i>
                    </button>
                </li>
            </ul>
            <p *ngIf="(comments$ | async)?.length === 0 && commentType === 'LESSON'" class="helper" style="margin-bottom: .5rem">Der Übungsstunde sind keine Kommentare zugewiesen.</p>
            <p *ngIf="(comments$ | async)?.length === 0 && commentType === 'GROUP'" class="helper" style="margin-bottom: .5rem">Der Übungsgruppe sind keine Kommentare zugewiesen.</p>
            <p *ngIf="(comments$ | async)?.length === 0 && commentType === 'PERSON'" class="helper" style="margin-bottom: .5rem">Dem Jungbläser sind keine Kommentare zugewiesen.</p>

        
        <button type="button" class="btn-primary btn-label" (click)="addComment()"><i
                class="fa-solid fa-plus"></i>Kommentar hinzufügen</button>
    </app-accordion>
</div>
