import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable, first, map } from 'rxjs';
import { loadPersonsAction } from 'src/app/modules/persons/state/persons.actions';
import { availableInstructors, availableParticipants } from 'src/app/modules/persons/state/persons.selector';
import { loadOpenTasksAction, loadTasksAction } from 'src/app/modules/task-handling/state/task-handling.actions';
import { allOpenTasks, topOpenTasks } from 'src/app/modules/task-handling/state/task-handling.selector';
import { getAmountOfParticipantsWithSameInstructor, getLessonById, getParticipationStateOfPersonById, isInstructorAdvised, isParticipantAdvised, taskOfParticipantForLesson } from '../../state/lesson.selector';
import { ActivatedRoute } from '@angular/router';
import { Lesson } from '../../models/Lesson';
import { addInstructorToGroupAction, addParticipantToGroupAction, addPractiseGroupLessonAction, addTaskToLessonAction, loadLessonByIdAction, loadLessonsAction, loadParticipationDataForLessonAction, removeInstructorFromGroupAction, removeParticipantFromGroupAction, removePractiseGroupLessonAction, removeTaskFromLessonAction } from '../../state/lesson.actions';
import { MatDialog } from '@angular/material/dialog';
import { AddTaskToLessonComponent } from '../../components/add-task-to-lesson/add-task-to-lesson.component';
import { AddPractiseGroupComponent } from '../../components/add-practise-group/add-practise-group.component';
import { commentsForPerson } from 'src/app/modules/comments/state/comment.selector';
import { addCommentAction, loadCommentsAction, removeCommentAction, updateCommentAction } from 'src/app/modules/comments/state/comment.action';
import { PractiseGroupInstructor, PractiseGroupParticipant } from '../../models/PractiseGroup';
import { CommentOverlayComponent } from 'src/app/modules/comments/components/comment-overlay/comment-overlay.component';

@Component({
  selector: 'app-lesson-preparation',
  templateUrl: './lesson-preparation.component.html',
  styleUrls: ['./lesson-preparation.component.scss']
})
export class LessonPreparationComponent {
  currentId = "";

  currentLesson$: Observable<Lesson>;

  openTasks$ = this.store.select(topOpenTasks);

  instructors$ = this.store.select(availableInstructors).pipe(map(ps => ps.map(p => {
    return { key: p.id, label: p.name + ", " + p.prename };
  })));

  instructorsRaw$ = this.store.select(availableInstructors);

  participants$ = this.store.select(availableParticipants);


  constructor(private fb: FormBuilder, private activeRoute: ActivatedRoute, private store: Store, private dialog: MatDialog) {
    this.currentId = this.activeRoute.snapshot.paramMap.get("id") as string;

    this.store.dispatch(loadLessonByIdAction({ id: this.currentId }));
    this.store.dispatch(loadTasksAction());
    this.store.dispatch(loadOpenTasksAction());
    this.store.dispatch(loadCommentsAction());
    this.store.dispatch(loadPersonsAction());
    this.store.dispatch(loadParticipationDataForLessonAction({ lId: this.currentId }));


    this.currentLesson$ = this.store.select(getLessonById(this.currentId)).pipe();
  }

  getOpenTasksForParticipant(pId: string) {
    return this.store.select(taskOfParticipantForLesson(pId, this.currentId));
  }

  getPersonState(pId: string) {
    return this.store.select(getParticipationStateOfPersonById(pId, this.currentId)).pipe();
  }

  getCommentsForParticipant(pId: string) {
    return this.store.select(commentsForPerson(pId, 2));
  }

  formatDate(dateString: any) {
    let date = new Date(Date.parse(dateString));

    let day = date.getDate();
    let month = date.getMonth() + 1;

    let str = "";
    str += ((day < 10) ? "0" : "") + day.toString() + ".";
    str += ((month < 10) ? "0" : "") + month.toString() + ".";

    return str + date.getFullYear();
  }

  addTask() {
    let dialogRef = this.dialog.open(AddTaskToLessonComponent, {
      width: "80%",
      maxHeight: "80%",
      data: {
        lessonId: this.currentId
      }
    });
    dialogRef.afterClosed()
      .pipe(first())
      .subscribe(result => {
        if (result === null || result === undefined)
          return;

        this.store.dispatch(addTaskToLessonAction({ lId: this.currentId, tId: result }))
      });
  }

  removeTask(id: string) {
    this.store.dispatch(removeTaskFromLessonAction({ lId: this.currentId, tId: id }));
  }

  addPractiseGroup() {
    let dialogRef = this.dialog.open(AddPractiseGroupComponent, {
      width: "80%",
      maxHeight: "80%"
    });

    dialogRef.afterClosed()
      .pipe(first())
      .subscribe(result => this.store.dispatch(addPractiseGroupLessonAction({ data: { lessonId: this.currentId, groupName: result } })));
  }

  removePractiseGroup(id: string) {
    this.store.dispatch(removePractiseGroupLessonAction({ id: id, lId: this.currentId }));
  }

  addGroupComment(id: string) {
    let dialogRef = this.dialog.open(CommentOverlayComponent, {
      width: "80%",
      maxHeight: "80%",
      data: {
        updateMode: false,
        content: null,
        commentType: "GROUP"
      }
    });

    dialogRef.afterClosed()
      .pipe(first())
      .subscribe(result => {
        if (result !== null && result !== undefined)
        this.store.dispatch(addCommentAction({
          data: {
            content: result,
            lessonId: this.currentId,
            personId: null,
            practiseGroupId: id
          }
        }));
      });
  }

  isParticipantAdvised(personId: string) {
    return this.store.select(isParticipantAdvised(personId, this.currentId));
  }

  isInstructorAdvised(personId: string) {
    return this.store.select(isInstructorAdvised(personId, this.currentId));
  }

  addParticipantToGroup(personId: string, groupId: string) {
    this.store.dispatch(addParticipantToGroupAction({ pId: personId, gId: groupId, lId: this.currentId }));
  }

  removeParticipantToGroup(personId: string, groupId: string) {
    this.store.dispatch(removeParticipantFromGroupAction({ pId: personId, gId: groupId, lId: this.currentId }));
  }

  addInstructorToGroup(personId: string, groupId: string) {
    this.store.dispatch(addInstructorToGroupAction({ pId: personId, gId: groupId, lId: this.currentId }));
  }

  removeInstructorFromGroup(personId: string, groupId: string) {
    this.store.dispatch(removeInstructorFromGroupAction({ pId: personId, gId: groupId, lId: this.currentId }));
  }

  checkInstructorUsedForGroup(personId: string, advisedInstructors: PractiseGroupInstructor[]) {
    return advisedInstructors.findIndex(i => i.id === personId) !== -1;
  }

  checkParticipantUsedForGroup(personId: string, advisedParticipants: PractiseGroupParticipant[]) {
    return advisedParticipants.findIndex(i => i.id === personId) !== -1;
  }

  updateGroupComment(id: string, content: string) {
    let dialogRef = this.dialog.open(CommentOverlayComponent, {
      width: "80%",
      maxHeight: "80%",
      data: {
        updateMode: true,
        content: content,
        commentType: "GROUP"
      }
    });

    dialogRef.afterClosed()
      .pipe(first())
      .subscribe(result =>  {
        if (result !== null && result !== undefined)
        this.store.dispatch(updateCommentAction({
          id: id, data: {
            content: result
          },
          lId: this.currentId
        }))
      });
  }

  formatDateForComment(dateStringLesson: any, dateStringLastUpdate: any) {

    let dateString;
    let prefix = "";
    if (dateStringLesson === null || dateStringLesson === undefined) {
      prefix = "";
      dateString = dateStringLastUpdate
    } else {
      prefix = "Ü-St. "
      dateString = dateStringLesson;
    }

    let date = new Date(Date.parse(dateString));

    let day = date.getDate();
    let month = date.getMonth() + 1;

    let str = "";
    str += ((day < 10) ? "0" : "") + day.toString() + ".";
    str += ((month < 10) ? "0" : "") + month.toString() + ".";

    return " - " + prefix + str + date.getFullYear();
  }

  getAmountOfParticipantsWithSameInstructor(id: string, gId: string) {
    return this.store.select(getAmountOfParticipantsWithSameInstructor(id, gId, this.currentId));
  }

  removeGroupComment(id: string) {
    this.store.dispatch(removeCommentAction({ id: id, lId: this.currentId }));
  }
}

