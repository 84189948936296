import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from "@angular/common/http"

import { AuthenticationRoutingModule } from './authentication-routing.module';
import { RegistrationComponent } from './views/registration/registration.component';
import { LoginComponent } from './views/login/login.component';
import { ReactiveFormsModule } from '@angular/forms';
import { EffectsModule } from '@ngrx/effects';
import { AuthenticationEffects } from './state/registration.effects';
import { StoreModule } from '@ngrx/store';
import { authFeatureKey, reducer } from './state/authorization.reducer';
import { ProfilePageComponent } from './views/profile-page/profile-page.component';
import { AdminPageComponent } from './views/admin-page/admin-page.component';


@NgModule({
  declarations: [
    RegistrationComponent,
    LoginComponent,
    ProfilePageComponent,
    AdminPageComponent
  ],
  imports: [
    CommonModule,
    AuthenticationRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    StoreModule.forFeature(authFeatureKey, reducer),    
    EffectsModule.forFeature([
      AuthenticationEffects
    ])
  ]
})
export class AuthenticationModule { }
