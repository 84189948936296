import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { environment } from 'src/environments/environment';
import { loadCommentsAction, loadCommentsSuccessAction } from '../state/comment.action';
import { Comment } from '../models/Comment';
import { loadLessonByIdAction, loadLessonsAction } from '../../lessons/state/lesson.actions';
import { CommentCreate } from '../models/CommentCreate';
import { CommentUpdate } from '../models/CommentUpdate';

const URL_REQ_ALL_COMMENTS = environment.baseUrl + "v1/comments";
const URL_ADD_GROUP_COMMENT = environment.baseUrl + "v1/comments";
const URL_EDIT_GROUP_COMMENT = environment.baseUrl + "v1/comments/:id";
const URL_DEL_GROUP_COMMENT = environment.baseUrl + "v1/comments/:id";

@Injectable({
  providedIn: 'root'
})
export class CommentService {

  constructor(private http: HttpClient, private store: Store) { }

  loadAllComments() {
    let token = localStorage.getItem("access_token") || "";
    this.http.get<Comment[]>(
      URL_REQ_ALL_COMMENTS,
      {
        headers: new HttpHeaders().set("Authorization", "Bearer " + token)
      }
    ).subscribe(
      {
        next: (data: Comment[]) => {
          this.store.dispatch(loadCommentsSuccessAction({data: data}));
        },
        error: (err) => console.log(err)
      }
    );
  }

  addComment(comment: CommentCreate) {
    let token = localStorage.getItem("access_token") || "";

    let url = URL_ADD_GROUP_COMMENT;

    this.http.post<void>(
      url,
      comment,
      {
        headers: new HttpHeaders().set("Authorization", "Bearer " + token)
      }
    ).subscribe(
      {
        next: () => {
          this.store.dispatch(loadLessonByIdAction({id: comment.lessonId ?? ""}));
          this.store.dispatch(loadCommentsAction());
        },
        error: (err) => console.log(err)
      }
    );
  }

  updateComment(id: string, comment: CommentUpdate, lId: string) {
    let token = localStorage.getItem("access_token") || "";

    let url = URL_EDIT_GROUP_COMMENT.replace(":id", id);

    this.http.put<void>(
      url,
      comment,
      {
        headers: new HttpHeaders().set("Authorization", "Bearer " + token)
      }
    ).subscribe(
      {
        next: () => {
          this.store.dispatch(loadLessonByIdAction({id: lId}));
          
          this.store.dispatch(loadCommentsAction());
        },
        error: (err) => console.log(err)
      }
    );
  }

  removeComment(id: string, lId: string) {
    let token = localStorage.getItem("access_token") || "";

    let url = URL_DEL_GROUP_COMMENT.replace(":id", id);

    this.http.delete<void>(
      url,
      {
        headers: new HttpHeaders().set("Authorization", "Bearer " + token)
      }
    ).subscribe(
      {
        next: () => {
          this.store.dispatch(loadCommentsAction());
          this.store.dispatch(loadLessonByIdAction({id: lId}));
          
        },
        error: (err) => console.log(err)
      }
    );
  }
}
