import { createReducer, on } from "@ngrx/store";
import * as TaskActions from "./task-handling.actions";
import { Task } from "../models/Task";

export const tasksFeatureKey = "task-handling";

export interface State {
    tasks: Task[],
    openTasks: Task[]
}

export const initalState: State = {
    tasks: [],
    openTasks: []
}

export const reducer = createReducer(
    initalState,
    on(TaskActions.loadTasksSuccessAction, (state, {data}) : State => {
        return {
            ...state,
            tasks: data
        };
    }),
    on(TaskActions.loadOpenTasksSuccessAction, (state, {data}) : State => {
        return {
            ...state,
            openTasks: data
        };
    })
);