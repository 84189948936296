import { Choir } from "./Choir";
import { UserRight } from "./UserRight";

export class User {
    id: string = "";
    name: string = "";
    prename: string = "";
    email: string = "";
    password? : string;
    isActive: boolean = false;
    rights: UserRight[] = [];
    choir: Choir = new Choir("", "");
}