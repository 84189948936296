import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { InstrumentsRoutingModule } from './instruments-routing.module';
import { InstumentsAdministrationComponent } from './views/instuments-administration/instuments-administration.component';
import { IntrumentsCreateComponent } from './views/intruments-create/intruments-create.component';
import { InstrumentsEditComponent } from './views/instruments-edit/instruments-edit.component';
import { StoreModule } from '@ngrx/store';
import { instrumentsFeatureKey, reducer } from './state/instruments.reducer';
import { EffectsModule } from '@ngrx/effects';
import { InstrumentsEffects } from './state/instruments.effects';
import { ReactiveFormsModule } from '@angular/forms';


@NgModule({
  declarations: [
    InstumentsAdministrationComponent,
    IntrumentsCreateComponent,
    InstrumentsEditComponent
  ],
  imports: [
    CommonModule,
    InstrumentsRoutingModule,
    ReactiveFormsModule,
    StoreModule.forFeature(instrumentsFeatureKey, reducer),
    EffectsModule.forFeature([
      InstrumentsEffects
    ])
  ]
})
export class InstrumentsModule { }
