import { createAction, props } from "@ngrx/store";
import { InstrumentCreate } from "../models/InstrumentCreate";
import { Instrument } from "../models/Instrument";

export const loadInstrumentsAction = createAction('[Instruments] Load instruments');

export const loadInstrumentsSuccessAction = createAction('[Instruments] Load instruments success', props<{data: Instrument[]}>());

export const addInstrumentAction = createAction('[Instruments] Add instrument', props<InstrumentCreate>());

export const editInstrumentAction = createAction('[Instruments] Edit instrument', props<{id: string, data: Instrument}>());

export const deleteInstrumentAction = createAction('[Instruments] Delete instrument', props<{id: string}>());