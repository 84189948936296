<div class="container">
    <h2>Instrumente</h2>
    <div class="toolbar">
        <button type="button" class="btn-primary btn-add" routerLink="/admin/instruments/add"><i class="fa-solid fa-plus"></i>Hinzufügen</button>
    </div>
    <div class="data">
        <div class="data-header">
            <h3 class="col">
                Instrument
            </h3>
            <h3></h3>
        </div>
        <div *ngFor="let instrument of this.instruments$ | async" class="data-entry">
            <div class="col instrument">
                {{instrument.name}}
            </div>
            <div class="col action">
                <button type="button" class="btn-secondary btn-add" [routerLink]="['/admin/instruments/edit', instrument.id]">
                    <i class="fa-solid fa-pen"></i>
                </button>
                <button type="button" class="btn-primary btn-add" (click)="deleteInstrument(instrument.id)">
                    <i class="fa-solid fa-trash"></i>
                </button>
            </div>
        </div>
    </div>
</div>
