import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { InstrumentCreate } from '../../models/InstrumentCreate';
import { addInstrumentAction } from '../../state/instruments.actions';
import { Router } from '@angular/router';

@Component({
  selector: 'app-intruments-create',
  templateUrl: './intruments-create.component.html',
  styleUrls: ['./intruments-create.component.scss']
})
export class IntrumentsCreateComponent {

  instrumentForm = this.fb.group({
    name: ["", [Validators.required]]
  });

  constructor(private fb: FormBuilder, private store: Store, private router: Router){
    
  }

  createInstrument() {
    let instrument = new InstrumentCreate();
    instrument.name = this.instrumentForm.value.name || "---";

    // Dispatch
    this.store.dispatch(addInstrumentAction(instrument));

    this.router.navigateByUrl("/admin/instruments");
  }
}
