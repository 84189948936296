import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { from, map, tap } from 'rxjs';
import { loadInstrumentsAction } from 'src/app/modules/instruments/state/instruments.actions';
import { availableInstruments } from 'src/app/modules/instruments/state/instruments.selector';
import { PersonCreate } from '../../models/PersonCreate';
import { CONFIG } from 'src/app/configuration';
import { addPersonAction } from '../../state/persons.actions';

@Component({
  selector: 'app-person-create',
  templateUrl: './person-create.component.html',
  styleUrls: ['./person-create.component.scss']
})
export class PersonCreateComponent {

  mode : "STUDENT" | "INSTRUCTOR" = "INSTRUCTOR";

  personForm = this.fb.group({
    prename: ["", [Validators.required]],
    name: ["", [Validators.required]],
    instrument: ["", [Validators.required]],
    voice: ["", [Validators.required]]
  });

  instruments$ = this.store.select(availableInstruments).pipe(map(instruments => instruments.map(instrument => {
    return {key: instrument.id, label: instrument.name};
  })));

  voices$ = from([[
    {key: "0b74aa61-6f16-4d75-9453-04fa5baf44ca", label: "Nicht definiert"},
    {key: "760a51d4-bfae-4c34-b48d-5d8313d34f03", label: "Sopran"},
    {key: "3034e300-930a-45b9-9b28-5094048a6e0c", label: "Alt"},
    {key: "fa113e18-b67f-44dd-8b92-ccfa1872a57a", label: "Tenor"},
    {key: "49a78a44-f81a-488d-aa63-bb9f6ee7c1d7", label: "Bass"}
  ]]);

  constructor(private fb: FormBuilder, private store: Store, private router: Router){
    this.store.dispatch(loadInstrumentsAction());
    
    let urlParams = this.router.url.split("/");
    this.mode = urlParams.findIndex(fragment => fragment === "instructors") === -1 ? "STUDENT" : "INSTRUCTOR";   
  }

  createPerson() {
    let person = new PersonCreate();
    person.name = this.personForm.controls.name.value || "";
    person.prename = this.personForm.controls.prename.value || "";
    person.instrument = this.personForm.controls.instrument.value || "";
    person.voice = this.personForm.controls.voice.value || "";
    person.role = this.mode === "INSTRUCTOR" ?  CONFIG.INSTRUCTOR_ID : CONFIG.PARTICIPANT_ID;
    
    this.store.dispatch(addPersonAction({data: person}));

    if (this.mode === "INSTRUCTOR"){
      this.router.navigateByUrl("/admin/instructors");
    } else {
      this.router.navigateByUrl("/admin/students");
    }
  }
}
