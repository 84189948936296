import { createReducer, on } from "@ngrx/store";
import * as InstrumentsActions from "./instruments.actions";
import { Instrument } from "../models/Instrument";

export const instrumentsFeatureKey = "instruments";

export interface State {
    instruments: Instrument[]
}

export const initalState: State = {
    instruments: []
}

export const reducer = createReducer(
    initalState,
    on(InstrumentsActions.loadInstrumentsSuccessAction, (state, {data}) : State => {
        return {
            instruments: data
        };
    })
);