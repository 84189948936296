export class TaskUpdate {
    /**
     * Id of task
     */
    id: string = "";

    /**
     * Title of task
     */
    title: string = "";

    /**
     * Is task deleted
     */
    isActive: boolean = false;
}