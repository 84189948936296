import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { environment } from 'src/environments/environment';
import { Person } from '../models/Person';
import { PersonCreate } from '../models/PersonCreate';
import { loadPersonsAction, loadPersonsActionSuccess } from '../state/persons.actions';
import { PersonUpdate } from '../models/PersonUpdate';

/* General perons urls */
const REQ_ALL_ACTIVE_URL = environment.baseUrl + "v1/persons";
const ADD_URL =  environment.baseUrl + "v1/persons";
const EDIT_URL = environment.baseUrl + "v1/persons/:id";
const DEL_URL = environment.baseUrl + "v1/persons/:id";


@Injectable({
  providedIn: 'root'
})
export class PersonService {

  constructor(private httpClient: HttpClient,  private store: Store, ) { }

  loadAllActivePersons() {
    let token = localStorage.getItem("access_token") || "";
    this.httpClient.get<Person[]>(REQ_ALL_ACTIVE_URL,
      {
        headers: new HttpHeaders().set("Authorization", "Bearer " + token)
      }).subscribe({
        next: (data: Person[]) => {
          this.store.dispatch(loadPersonsActionSuccess({data: data}));
        },
        error: (err) => console.log(err)
      }
    );
  }

  addPerson(person: PersonCreate) {
    let token = localStorage.getItem("access_token") || "";
    this.httpClient.post(ADD_URL,
      person,
      {
        headers: new HttpHeaders().set("Authorization", "Bearer " + token)
      }).subscribe({
        next: () => {
          this.store.dispatch(loadPersonsAction());
        },
        error: (err) => console.log(err)
      }
    );
  }

  editPerson(id: string, person: PersonUpdate) {
    let token = localStorage.getItem("access_token") || "";
    this.httpClient.put(
      EDIT_URL.replace(":id", id),
      person,
      {
        headers: new HttpHeaders().set("Authorization", "Bearer " + token)
      }).subscribe({
        next: () => {
          this.store.dispatch(loadPersonsAction());
        },
        error: (err) => console.log(err)
      }
    );
  }

  deletePerson(id: string) {
    let token = localStorage.getItem("access_token") || "";
    this.httpClient.delete(DEL_URL.replace(":id", id),
    {
      headers: new HttpHeaders().set("Authorization", "Bearer " + token)
    }).subscribe({
      next: () => {
        this.store.dispatch(loadPersonsAction());
      },
      error: (err) => console.log(err)
    }
  );
  }
}
