import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { loadLessonsAction } from '../../state/lesson.actions';
import { loadPersonsAction } from '../../../persons/state/persons.actions';
import { Router } from '@angular/router';
import { currentLessonOrUpcomming } from '../../state/lesson.selector';

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss']
})
export class OverviewComponent {
  currentLesson$ = this.store.select(currentLessonOrUpcomming).pipe();

  constructor(private store: Store, private router: Router){
    // this.store.dispatch(loadPersonsAction());
    this.store.dispatch(loadLessonsAction());
  }
}
