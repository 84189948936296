<form [formGroup]="addTaskForm" (ngSubmit)="close(true)" autocomplete="off">
    <header>
        <h2>Aufgabe hinzufügen</h2>
    </header>
    <app-drop-down 
            inputId="task"
            label="Übung"
            [entries$]="openNotAdvisedTasks$"
            [control]="addTaskForm.controls['task']"
            errorMessage="Es muss eine Übung ausgewählt werden!"
            >
        </app-drop-down>
    <footer>
        <button type="submit" [disabled]="!addTaskForm.valid"  id="add-group" class="btn-primary">Hinzufügen</button>
        <button (click)="close(false)" id="add-group" type="button" class="btn-secondary">Abbrechen</button>
    </footer>
</form>
