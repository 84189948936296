import { createReducer, on } from "@ngrx/store";
import * as LessonsActions from "./lesson.actions";
import { Lesson } from "../models/Lesson";
import { Participation } from "../models/Participation";

export const lessonsFeatureKey = "lessons";

export class ParticipationCollectionEntry {
    lId: string = "";
    data: Participation[] = [];
}

export interface State {
    lessons: Lesson[],
    participation: ParticipationCollectionEntry[],
    error: string,
    loading: boolean
}

export const initalState: State = {
    lessons: [],
    participation: [],
    error: "",
    loading: false
}

export const reducer = createReducer(
    initalState,
    on(LessonsActions.loadLessonsAction, (state) : State=> {
        return {
            ... state,
            loading: true
        }
    }),
    on(LessonsActions.loadLessonsSuccessAction, (state, {data}) : State => {
        if(state.lessons.length === 0){
            return {
                ...state,
                lessons: data,
                loading: false
            }
        }

        let lessons : Lesson[] = state.lessons.slice();

        for(let idx = 0; idx < data.length; idx++){
            if (state.lessons.findIndex(l => l.id === data[idx].id) === -1){
                console.log("Lesson added");
                lessons.push(data[idx]);
            }
        }
        
        return {
            ... state,
            lessons: lessons,
            loading: false
        };
    }),
    on(LessonsActions.loadLessonByIdSuccessAction, (state, {data}) : State => {
        let lessons = state.lessons.map(x => x);
        
        let idx = lessons.findIndex(l => l.id === data.id);
        if (idx != -1)
        {
            lessons[idx] = data;
        }
        else {
            lessons.push(data);
        }

        return {
            ... state,
            lessons: lessons,
            loading: false
        };
    }),
    on(
        LessonsActions.loadLessonsFailAction,
        LessonsActions.loadLessonByIdFailAction,
        (state, {error}) : State=> {
        return {
            ... state,
            loading: false,
            error: error
        }
    }),


    on(LessonsActions.loadParticipationDataForLessonActionSuccess, (state, {lId, data}) : State => {
        let participation = initalState.participation;
        
        let idx = participation.findIndex(l => l.lId == lId);
        let participationEntry: ParticipationCollectionEntry = {lId: lId, data: data};

        if (idx !== -1)
        {
            participation[idx] = participationEntry;
        }
        else
        {
            if (participation.length === 0){
                participation = [participationEntry];
            } else {
                participation.push(participationEntry);
            }
        }

        return {
            ... state,
            participation: participation
        };
    })
);