<div class="container">
    <h2>Übungsstunde vorbereiten</h2>

    <!-- General information -->
    <app-general-card [lesson$]="currentLesson$" [lessonId]="currentId"></app-general-card>

    <app-accordion title="Tägliche Übungen" [collapsed]="true">
        <div class="display">
            <ul class="tasks">
                <li *ngFor="let task of (currentLesson$ | async)?.tasks">
                    <p>{{task.title}} (Kap.:{{task.chapter}}, Nr.:{{task.number}})</p>
                    <button (click)="removeTask(task.id)" type="button" class="btn-secondary btn-ico">
                        <i class="fa-solid fa-trash"></i>
                    </button>
                </li>
            </ul>
            <button (click)="addTask()" id="add-group" type="button" class="btn-primary btn-label"><i
                    class="fa-solid fa-plus"></i>Aufgabe hinzufügen</button>
        </div>
    </app-accordion>

    <app-accordion title="Übungsgruppen" [collapsed]="true">
        <app-accordion class="practise-group" *ngFor="let group of (currentLesson$ | async)?.groups"
            [title]="group.groupName">

            <h3>Gruppen - Kommentare</h3>
            <div class="group-comments">
                <ul class="comments">
                    <li *ngFor="let comment of group.comments">
                        <p><span class="helper">{{comment.name}}, {{comment.prename}}:</span>&nbsp;<span
                                class="content">{{comment.content}}</span></p>
                        <button (click)="updateGroupComment(comment.id, comment.content)" type="button"
                            class="btn-primary btn-ico">
                            <i class="fa-solid fa-pen"></i>
                        </button>
                        <button (click)="removeGroupComment(comment.id)" type="button"
                            class="btn-secondary btn-add btn-ico">
                            <i class="fa-solid fa-trash"></i>
                        </button>
                    </li>
                </ul>
                <p *ngIf="group.comments.length == 0" class="helper" style="margin-bottom: .5rem">Der Gruppe sind keine Kommentare zugewiesen.</p>

                <button type="button" class="btn-primary btn-label" (click)="addGroupComment(group.id)"><i
                        class="fa-solid fa-plus"></i>Kommentar hinzufügen</button>
            </div>

            <h3>Jungbläser Anleiter</h3>
            <div class="group-instructors">
                <ul class="instructors">

                    <li *ngFor="let instructor of group.instructors">
                        <div>
                            <p class="content">{{instructor.name}}, {{instructor.prename}}</p>
                        </div>
                    </li>
                    <p *ngIf="group.instructors.length == 0" class="helper">Der Gruppe sind keine Anleiter zugewiesen.</p>
                </ul>
            </div>

            <h3>Jungbläser</h3>
            <div class="group-participants">
                <app-accordion *ngFor="let partitipant of group.participants"
                    [errorColor]="(getPersonState(partitipant.id) | async) == 2"
                    [actionRequired]="!(isParticipantAdvised(partitipant.id) | async)"
                    [subtitle]="partitipant.instrument.name" [title]="partitipant.name + ', ' + partitipant.prename"
                    [collapsed]="true">
                    <div class="open-tasks">
                        <h4>Offen Übungen</h4>
                        <ul>
                            <li class="row-small" *ngFor="let task of (getOpenTasksForParticipant(partitipant.id) | async)">
                                <p>{{task.title}} (Kap.: {{task.chapter}} - Nr.:{{task.number}})</p>
                            </li>
                        </ul>
                        <p *ngIf="(getOpenTasksForParticipant(partitipant.id) | async)?.length === 0" class="helper">Keine
                            offenen Aufgaben vorhanden</p>
                    </div>
                    <div class="last-comments">
                        <h4>Letzten 2 Kommentare</h4>
                        <ul class="comments">
                            <li class="row-small"
                                *ngFor="let comment of (getCommentsForParticipant(partitipant.id) | async)">
                                <div class="comment">
                                    <p class="content">{{comment.content}}</p>
                                    <p class="helper">{{comment.creatorName}},
                                        {{comment.creatorPrename}}{{formatDateForComment(comment.lessonDate,
                                        comment.lastUpdate)}}</p>
                                </div>
                            </li>
                        </ul>
                        <p *ngIf="(getCommentsForParticipant(partitipant.id) | async)?.length === 0" class="helper">Keine
                            Kommentare vorhanden</p>
                    </div>
                </app-accordion>
                <p *ngIf="group.participants.length == 0" class="helper">Der Gruppe sind keine Jungbläser zugewiesen.</p>

            </div>
            <button type="button" class="btn-secondary btn-label" (click)="removePractiseGroup(group.id)"><i
                    class="fa-solid fa-trash"></i>Gruppe entfernen</button>
        </app-accordion>
        <button id="add-group" type="button" class="btn-primary btn-label" (click)="addPractiseGroup()"><i
                class="fa-solid fa-plus"></i>Gruppe erstellen</button>
    </app-accordion>

    <app-accordion title="Jungbläser" [collapsed]="true">
        <div class="group-participants">
            <app-accordion *ngFor="let partitipant of (participants$ | async)"
                [errorColor]="(getPersonState(partitipant.id) | async) == 2"
                [actionRequired]="!(isParticipantAdvised(partitipant.id) | async) && (getPersonState(partitipant.id) | async) != 2"
                [collapsed]="(isParticipantAdvised(partitipant.id) | async) || (getPersonState(partitipant.id) | async) == 2 || false"
                [subtitle]="partitipant.instrument.name" [title]="partitipant.name + ', ' + partitipant.prename">
                <div class="open-tasks">
                    <h4>Offen Übungen</h4>
                    <ul>
                        <li class="row-small" *ngFor="let task of (getOpenTasksForParticipant(partitipant.id) | async)">
                            <p>{{task.title}} (Kap.: {{task.chapter}} - Nr.:{{task.number}})</p>
                        </li>
                    </ul>
                    <p *ngIf="(getOpenTasksForParticipant(partitipant.id) | async)?.length === 0" class="helper">Keine
                        offenen Aufgaben vorhanden</p>
                </div>
                <div class="last-comments">
                    <h4>Letzten 2 Kommentare</h4>
                    <ul class="comments">
                        <li class="row-small"
                            *ngFor="let comment of (getCommentsForParticipant(partitipant.id) | async)">
                            <div class="comment">
                                <p class="content">{{comment.content}}</p>
                                <p class="helper">{{comment.creatorName}},
                                    {{comment.creatorPrename}}{{formatDateForComment(comment.lessonDate,
                                    comment.lastUpdate)}}</p>
                            </div>
                        </li>
                    </ul>
                    <p *ngIf="(getCommentsForParticipant(partitipant.id) | async)?.length === 0" class="helper">Keine
                        Kommentare vorhanden</p>
                </div>

                <div class="group-information-participant" *ngFor="let group of (currentLesson$ | async)?.groups">
                    <h4>
                        {{group.groupName}}
                    </h4>
                    <p class="helper">{{group.participants.length}} Jungbläser sind bereits zugewiesen.</p>
                    <button *ngIf="!(isParticipantAdvised(partitipant.id) | async)" type="button" class="btn-primary btn-label" (click)="addParticipantToGroup(partitipant.id, group.id)"><i
                        class="fa-solid fa-plus"></i>Hinzufügen</button>
                    <button  *ngIf="checkParticipantUsedForGroup(partitipant.id, group.participants)" type="button" class="btn-secondary btn-label" (click)="removeParticipantToGroup(partitipant.id, group.id)"><i
                        class="fa-solid fa-xmark"></i>Entfernen</button>
                </div>
            </app-accordion>
        </div>
    </app-accordion>

    <app-accordion title="Anleiter" [collapsed]="true">
        <div class="group-instructors">
            <app-accordion *ngFor="let instructor of instructorsRaw$ | async"
            [errorColor]="(getPersonState(instructor.id) | async) == 2"
            [actionRequired]="!(isInstructorAdvised(instructor.id) | async) && (getPersonState(instructor.id) | async) != 2"
                [title]="instructor.name + ', ' + instructor.prename"
                [collapsed]="(isInstructorAdvised(instructor.id) | async) || (getPersonState(instructor.id) | async) == 2 || false"
                >

                <div class="group-information-instructor" *ngFor="let group of (currentLesson$ | async)?.groups">
                    <h4>
                        {{group.groupName}}
                    </h4>
                    <p class="helper">{{getAmountOfParticipantsWithSameInstructor(instructor.id, group.id) | async}} von {{group.participants.length}} Jungbläser haben den selben Anleiter.</p>
                    <p class="helper">{{group.instructors.length}} Anleiter ist/sind zugewiesen.</p>
                    <button *ngIf="!(isInstructorAdvised(instructor.id) | async)" type="button" class="btn-primary btn-label" (click)="addInstructorToGroup(instructor.id, group.id)"><i
                        class="fa-solid fa-plus"></i>Hinzufügen</button>
                    <button  *ngIf="checkInstructorUsedForGroup(instructor.id, group.instructors)" type="button" class="btn-secondary btn-label" (click)="removeInstructorFromGroup(instructor.id, group.id)"><i
                        class="fa-solid fa-xmark"></i>Entfernen</button>
                </div>
            </app-accordion>
        </div>
    </app-accordion>
</div>