import { createAction, props } from "@ngrx/store";
import { Lesson } from "../models/Lesson";
import { LessonCreateUpdate } from "../models/LessonCreateUpdate";
import { PractiseGroupCreate } from "../models/PractiseGroupCreate";
import { Participation } from "../models/Participation";
import { ParticipationUpdate } from "../models/ParticipationUpdate";

export const loadLessonsAction = 
    createAction('[Lessons] Load lessons');

export const loadLessonsSuccessAction = 
    createAction('[Lessons API] Load lessons success', props<{data: Lesson[]}>());

export const loadLessonsFailAction = 
    createAction('[Lessons API] Load lessons fail', props<{error: string}>());

export const loadLessonByIdAction = 
    createAction('[Lessons] Load lesson by id', props<{id: string}>());

export const loadLastLessonBeforeCurrent = 
    createAction('[Lessons] Load last lesson by currents id', props<{id: string}>());
    

export const loadLessonByIdSuccessAction = 
    createAction('[Lessons API] Load lesson by id success', props<{data: Lesson}>());

export const loadLessonByIdFailAction = 
    createAction('[Lessons API] Load lesson by id fail', props<{error: string}>());



    // Rework everything below -> Currently every thign will be reloaded

export const addLessonAction = 
    createAction('[Lessons] Create lesson', props<{data: LessonCreateUpdate}>());

export const editLessonAction = 
    createAction('[Lessons] Edit lesson', props<{id: string, data: LessonCreateUpdate}>());

export const addTaskToLessonAction =
    createAction('[Lessons] Add task from lesson', props<{lId: string, tId: string}>());

export const removeTaskFromLessonAction =
    createAction('[Lessons] Remove task from lesson', props<{lId: string, tId: string}>());

export const addPractiseGroupLessonAction =
    createAction('[Practise Groups] Add practise group', props<{data: PractiseGroupCreate}>());

export const removePractiseGroupLessonAction =
    createAction('[Practise Groups] Remove practise group', props<{id: string, lId: string}>()); 

export const addParticipantToGroupAction =
    createAction('[Practise Groups] Add participant to practise group', props<{pId: string, gId: string, lId: string}>());

export const removeParticipantFromGroupAction =
    createAction('[Practise Groups] Remove participant from practise group', props<{pId: string, gId: string, lId: string}>());

export const addInstructorToGroupAction =
    createAction('[Practise Groups] Add instructor to practise group', props<{pId: string, gId: string, lId: string}>());

export const removeInstructorFromGroupAction =
    createAction('[Practise Groups] Remove instructor from practise group', props<{pId: string, gId: string, lId: string}>());
    
export const loadParticipationDataForLessonAction =
    createAction('[Participation] Load participation information about one lesson', props<{lId: string}>());

export const loadParticipationDataForLessonActionSuccess =
    createAction('[Participation] Load participation information about one lesson success', props<{lId: string, data: Participation[]}>());

export const updateParticipationLessonPersonAction =
    createAction('[Participation] Update participation for lesson for person', props<{pId: string, lId: string, data: ParticipationUpdate}>());