import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, find, findIndex, map, of, tap } from 'rxjs';

import { dataReadyForUse, selectUserRights } from "../state/authorization.selector";
import { CONFIG } from 'src/app/configuration';


@Injectable({
  providedIn: 'root'
})
export class BasicGuard  {

  constructor(private router: Router, private store: Store) {

  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean> {

    return new Promise((resolve, reject) => {
      let readyForUse = this.store.select(dataReadyForUse);
      readyForUse.subscribe({
        next: (value) => {
          if (value) {
            let result = this.store.select(selectUserRights).pipe(map(data => data.findIndex(r => r.id === CONFIG.BASIC_RIGHT_ID) !== -1));

            result.subscribe({
              next: (data: boolean) => {
                resolve(data);
              },
              error: (err: any) => {
                console.log("error", err);
              }
            });
          }
        },
        error: err => {
          resolve(false);
        }
      });
    });
  }

}
