import { NIL as NIL_UUID } from "uuid";

export class Choir {
    id: string = NIL_UUID;
    name: string = "";

    constructor(id: string, name: string) {
        this.id = id;
        this.name = name;
    }
}