import { Instrument } from "../../instruments/models/Instrument";
import { Role } from "./Role";
import { Voice } from "./Voice";

export class Person {
    id: string = "";
    name: string = "";
    prename: string = "";
    voice: Voice = new Voice();
    role: Role = new Role();
    instrument: Instrument = new Instrument();

}