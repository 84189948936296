import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { loadLessonsAction } from '../../state/lesson.actions';
import { allLessons } from '../../state/lesson.selector';
import { loadPersonsAction } from 'src/app/modules/persons/state/persons.actions';

@Component({
  selector: 'app-lessons-list',
  templateUrl: './lessons-list.component.html',
  styleUrls: ['./lessons-list.component.scss']
})
export class LessonsListComponent {
  lessons$ = this.store.select(allLessons).pipe();

  constructor(private store: Store) {
    this.store.dispatch(loadPersonsAction());
    this.store.dispatch(loadLessonsAction());

  }

  formatLessonHeading(dateString: any) {
    let date = new Date(Date.parse(dateString));

    let day = date.getDate();
    let month = date.getMonth() + 1;

    let str = "";
    str += ((day < 10) ? "0" : "") + day.toString() + ".";
    str += ((month < 10) ? "0" : "") + month.toString() + ".";

    return str + date.getFullYear();
  }
}
