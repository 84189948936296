import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { ToastService } from '../../toaster/services/toast.service';
import { Task } from '../models/Task';
import { TaskCreate } from '../models/TaskCreate';
import { TaskUpdate } from '../models/TaskUpdate';
import { environment } from 'src/environments/environment';
import { loadOpenTasksSuccessAction, loadTasksAction, loadTasksSuccessAction } from '../state/task-handling.actions';
import { TaskProgress } from '../models/TaskProgress';
import { loadLessonByIdAction, loadLessonsAction } from '../../lessons/state/lesson.actions';

const REQ_ALL_TASK_URL = environment.baseUrl + "v1/task-handling/task";
const REQ_OPEN_TASK_URL = environment.baseUrl + "v1/task-handling/task?taskState=1";
const ADD_TASK_URL = environment.baseUrl + "v1/task-handling/task";
const EDIT_TASK_URL = environment.baseUrl + "v1/task-handling/task/:id";
const DEL_TASK_URL = environment.baseUrl + "v1/task-handling/task/:id";

const URL_EDIT_TASK_PROG = environment.baseUrl + "v1/task-handling/task/:id/person";

@Injectable({
  providedIn: 'root'
})
export class TaskHandlingService {

  constructor(private httpClient: HttpClient,  private store: Store, private router: Router, private toastService: ToastService) { }

  loadAllTasks() {
    let token = localStorage.getItem("access_token") || "";
    this.httpClient.get<Task[]>(REQ_ALL_TASK_URL,
      {
        headers: new HttpHeaders().set("Authorization", "Bearer " + token)
      }).subscribe({
        next: (data: Task[]) => {
          this.store.dispatch(loadTasksSuccessAction({data: data}));
        },
        error: (err) => console.log(err)
      }
    );
  }

  loadAllOpenTasks() {
    let token = localStorage.getItem("access_token") || "";
    this.httpClient.get<Task[]>(REQ_OPEN_TASK_URL,
      {
        headers: new HttpHeaders().set("Authorization", "Bearer " + token)
      }).subscribe({
        next: (data: Task[]) => {
          this.store.dispatch(loadOpenTasksSuccessAction({data: data}));
        },
        error: (err) => console.log(err)
      }
    );
  }

  addTask(task: TaskCreate) {
    let token = localStorage.getItem("access_token") || "";
    this.httpClient.post(ADD_TASK_URL,
      task,
      {
        headers: new HttpHeaders().set("Authorization", "Bearer " + token)
      }).subscribe({
        next: () => {
          this.store.dispatch(loadTasksAction());
        },
        error: (err) => console.log(err)
      }
    );
  }

  editTask(id: string, task: TaskUpdate) {
    let token = localStorage.getItem("access_token") || "";
    this.httpClient.put(
      EDIT_TASK_URL.replace(":id", id),
      task,
      {
        headers: new HttpHeaders().set("Authorization", "Bearer " + token)
      }).subscribe({
        next: () => {
          this.store.dispatch(loadTasksAction());
        },
        error: (err) => console.log(err)
      }
    );
  }

  deleteTask(id: string) {
    let token = localStorage.getItem("access_token") || "";
    this.httpClient.delete(DEL_TASK_URL.replace(":id", id),
      {
        headers: new HttpHeaders().set("Authorization", "Bearer " + token)
      }).subscribe({
        next: () => {
          this.store.dispatch(loadTasksAction());
        },
        error: (err) => console.log(err)
      }
    );
  } 


  updateTaskProgress(data: TaskProgress, lId: string) {
    let url = URL_EDIT_TASK_PROG.replace(":id", data.taskId);

    let token = localStorage.getItem("access_token") || "";

    this.httpClient.put(
      url,
      data,
      {
        headers: new HttpHeaders().set("Authorization", "Bearer " + token)
      }).subscribe({
        next: () => {
          this.store.dispatch(loadTasksAction());
          this.store.dispatch(loadLessonByIdAction({id: lId}));
          
        },
        error: (err) => console.log(err)
      }
    );
  }
}
