import { Component, Input } from '@angular/core';
import { ToastService } from '../../services/toast.service';
import { Message } from '../../models/Message';


@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss']
})
export class ToastComponent {
  @Input()
  message: Message = {
    heading: "Überschrift",
    data: "Das ist die Nachricht",
    type: "error"
  };

  constructor(private toastService: ToastService) {
  }

  closeMessage(){
    this.toastService.closeCurrentMessage();
  }
}
