import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { activateLoginAction, changeUserRights, deactivateLoginAction, requestLoginAction } from '../../state/registration.action';
import { availableLogins } from '../../state/authorization.selector';
import { CONFIG } from 'src/app/configuration';
import { config } from 'rxjs';
import { User } from '../../models/User';

@Component({
  selector: 'app-admin-page',
  templateUrl: './admin-page.component.html',
  styleUrls: ['./admin-page.component.scss']
})
export class AdminPageComponent {

  users$ = this.store.select(availableLogins).pipe();

  constructor(private store: Store) {
    this.store.dispatch(requestLoginAction());
  }

  activateUser(id: string) {
    this.store.dispatch(activateLoginAction({userId: id}));
  }

  deactivateUser(id: string) {
    this.store.dispatch(deactivateLoginAction({userId: id}));
  }

  setUserAsAdmin(id: string) {
    this.store.dispatch(changeUserRights({userId: id, rights: [CONFIG.BASIC_RIGHT_ID, CONFIG.ADMIN_RIGHT_ID]}));
  }

  resetUserAsAdmin(id: string) {
    this.store.dispatch(changeUserRights({userId: id, rights: [CONFIG.BASIC_RIGHT_ID]}));
  }

  isUserAdmin(user: User) : boolean{
    return user.rights.findIndex(right => right.id === CONFIG.ADMIN_RIGHT_ID) !== -1;
  }
}
