<div class="container">
    <header (click)="toggleState()" [class.user-req]="actionRequired" [class.user-err]="errorColor">
        <div>
            <h3>{{title}}</h3>
            <p *ngIf="subtitle !== ''" class="helper">{{subtitle}}</p>
        </div>
        <i *ngIf="collapsed" class="fa-solid fa-chevron-down"></i>
        <i *ngIf="!collapsed" class="fa-solid fa-chevron-up"></i>
    </header>
    <div class="content" *ngIf="!collapsed">
        <ng-content></ng-content>
    </div>
</div>
