import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PersonsRoutingModule } from './persons-routing.module';
import { ReactiveFormsModule } from '@angular/forms';
import { EffectsModule } from '@ngrx/effects';
import { PersonsEffects } from './state/persons.effects';
import { StoreModule } from '@ngrx/store';
import { personsFeatureKey, reducer } from './state/persons.reducer';
import { InstructorOverviewComponent } from './views/instructor-overview/instructor-overview.component';
import { StudentOverviewComponent } from './views/student-overview/student-overview.component';
import { PersonCreateComponent } from './components/person-create/person-create.component';
import { PersonEditComponent } from './components/person-edit/person-edit.component';
import { BasicControlsModule } from '../basic-controls/basic-controls.module';


@NgModule({
  declarations: [
    InstructorOverviewComponent,
    StudentOverviewComponent,
    PersonCreateComponent,
    PersonEditComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    PersonsRoutingModule,
    BasicControlsModule,
    StoreModule.forFeature(personsFeatureKey, reducer),
    EffectsModule.forFeature([PersonsEffects])
  ]
})
export class PersonsModule { }
