<div class="input-group">
    <label [for]="inputId"><span *ngIf="required">* </span>{{label}}</label>
    <select
        [formControl]="control" 
        [name]="inputId" 
        [id]="inputId">
            <option *ngFor="let item of entries$ | async" [value]="item.key">{{item.label}}</option>
    </select>
    <p *ngIf="control.touched && !control.valid"  class="error-text" >
        {{errorMessage}}
    </p>
</div>
