<div class="container">
    <h2>Benutzerverwaltung</h2>
    <div class="data">
        <!-- Only display if table display -->
        <div class="data-header">
            <h3 class="col">
                Vorname
            </h3>
            <h3 class="col">
                Nachname
            </h3>
            <h3 class="col">
                Email
            </h3>
            <h3 class="col">
                Status
            </h3>
            <h3 class="col">
                Aktionen
            </h3>
        </div>
        <div *ngFor="let user of this.users$ | async" class="data-entry">
            <div class="col prename">
                {{user.prename}}
            </div>
            <div class="col name">
                {{user.name}}
            </div>
            <div class="col email">
                {{user.email}}
            </div>
            <div class="col state">
                <i *ngIf="user.isActive" class="fa-solid fa-check enabled"></i>
                <i *ngIf="!user.isActive" class="fa-solid fa-xmark disabled"></i>
            </div>
            <div class="col action">
                <button *ngIf="!isUserAdmin(user)" (click)="setUserAsAdmin(user.id)" type="button" class="btn-primary">Set as Admin</button>
                <button *ngIf="isUserAdmin(user)" (click)="resetUserAsAdmin(user.id)" type="button" class="btn-secondary">Reset as Admin</button>
                <button *ngIf="!user.isActive" (click)="activateUser(user.id)" type="button" class="btn-primary">Aktivieren</button>
                <button *ngIf="user.isActive" (click)="deactivateUser(user.id)" type="button" class="btn-secondary">Deaktivieren</button>
            </div>
        </div>
    </div>
</div>
