import { Component, OnDestroy } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, Subject, first, map, takeUntil } from 'rxjs';
import { Lesson } from '../../models/Lesson';
import { loadLessonByIdAction, loadLessonsAction } from '../../state/lesson.actions';
import { getAllPractiseGroupsByLesson, getLessonById, getPractiseGroupsById, taskOfParticipantForLesson } from '../../state/lesson.selector';
import { PractiseGroup } from '../../models/PractiseGroup';
import { SelectPractiseGroupComponent } from '../../components/select-practise-group/select-practise-group.component';
import { getTaskProgressForParticipant } from 'src/app/modules/task-handling/state/task-handling.selector';
import { TaskProgress } from 'src/app/modules/task-handling/models/TaskProgress';
import { loadOpenTasksAction, loadTasksAction, updateTaskState } from 'src/app/modules/task-handling/state/task-handling.actions';
import { TaskProgressState } from 'src/app/modules/task-handling/models/TaskProgressState';
import { loginAction } from 'src/app/modules/authentication/state/registration.action';
import { loadCommentsAction } from 'src/app/modules/comments/state/comment.action';
import { selectUserRights } from 'src/app/modules/authentication/state/authorization.selector';
import { CONFIG } from 'src/app/configuration';

const KEY_CURRENT_GRP = "current-practise-group";

@Component({
  selector: 'app-lesson-reworking',
  templateUrl: './lesson-reworking.component.html',
  styleUrls: ['./lesson-reworking.component.scss']
})
export class LessonReworkingComponent implements OnDestroy {
  notifier = new Subject<void>();

  currentId = "";

  currentLesson$ : Observable<Lesson>;
  currentLesson!: Lesson;
  availableGroups$ : Observable<PractiseGroup[]>;

  currentGroupId : string | null = null;
  currentGroup : PractiseGroup | null = null;

  adminUserNotLoggedIn$ = this.store.select(selectUserRights).pipe(map(data => data.findIndex(r => r.id === CONFIG.ADMIN_RIGHT_ID) === -1));

  constructor(
    private fb: FormBuilder, 
    private activeRoute: ActivatedRoute, 
    private store: Store, 
    private dialog: MatDialog) 
  {
    this.currentId = this.activeRoute.snapshot.paramMap.get("id") as string;
    this.store.dispatch(loadCommentsAction());
    this.store.dispatch(loadTasksAction());
    this.store.dispatch(loadOpenTasksAction());
    this.store.dispatch(loadLessonByIdAction({id: this.currentId}));
    this.currentLesson$ = this.store.select(getLessonById(this.currentId));
    this.currentLesson$.pipe(takeUntil(this.notifier)).subscribe({
      next: value => {
        this.currentLesson = value;
      }
    });

    this.availableGroups$ = this.store.select(getAllPractiseGroupsByLesson(this.currentId));

    this.loadCurrentGroup();
  }

  ngOnDestroy(): void {
    this.saveCurrentGroup();
    this.notifier.next();
    this.notifier.complete();
  }

  getOpenTasksForParticipant(pId: string) {
    return this.store.select(taskOfParticipantForLesson(pId, this.currentId));
  }

  getTaskProgress(tId: string, pId: string) {
    return this.store.select(getTaskProgressForParticipant(tId, pId));
  }

  startTask(data: Observable<TaskProgress | null | undefined>) {
    data.pipe(first()).subscribe({
      next: value => {
      if (value === null || value === undefined)
        return;

        let date = new Date(Date.parse(this.currentLesson.eventDate as string));
        console.log("Start task");


        this.store.dispatch(updateTaskState({data: {
        comment: value.comment,
        personId: value.personId,
        taskId: value.taskId,
        state: TaskProgressState.PLANNED,
        endDate: null,
        startDate: this.formatDateObjAsString(this.addDaysToDate(date, 1))
      }, lId: this.currentId}));
    }
  });
  }

  addDaysToDate(currentDate: Date, amountOfDays: number): Date {
    currentDate.setDate(currentDate.getDate() + amountOfDays);
    
    return currentDate;
  }

  reopenTask(data: Observable<TaskProgress | null | undefined>) {
    data.pipe(first()).subscribe(value => {
      if (value === null || value === undefined)
        return;

        this.store.dispatch(updateTaskState({data: {
          comment: value.comment,
          personId: value.personId,
          taskId: value.taskId,
          state: TaskProgressState.PLANNED,
          endDate: null,
          startDate: this.formatDateObjAsString(new Date(Date.parse(value.startDate as string)))
        }, lId: this.currentId}));
    });
  }

  finishTask(data: Observable<TaskProgress | null | undefined>) {
    data.pipe(first()).subscribe(value => {
      if (value === null || value === undefined)
        return;

        let date = new Date(Date.parse(this.currentLesson.eventDate as string));

        this.store.dispatch(updateTaskState({data: {
          comment: value.comment,
          personId: value.personId,
          taskId: value.taskId,
          state: TaskProgressState.COMPLETE,
          endDate: this.formatDateObjAsString(this.addDaysToDate(date, 1)),
          startDate: this.formatDateObjAsString(this.addDaysToDate(new Date(Date.parse(this.currentLesson.eventDate as string)), 1))
        },
      lId: this.currentId}));
    });
  }

  openGroupSelection() {
    let dialogRef = this.dialog.open(SelectPractiseGroupComponent, {
      width: "80%",
      maxHeight: "80%",
      data: {
        availableGroups$: this.availableGroups$
      }
    });
    dialogRef.afterClosed()
    .pipe(first())
    .subscribe(result => {
      if (result === null || result === undefined)
        return;

      this.currentGroupId = result;
      this.saveCurrentGroup();
      this.loadCurrentGroup()
    });
  }

  saveCurrentGroup() {
    if (this.currentGroupId !== "" && this.currentGroupId !== null)
        localStorage.setItem(KEY_CURRENT_GRP, this.currentGroupId);
      else
        localStorage.removeItem(KEY_CURRENT_GRP);
  }

  loadCurrentGroup() {
    let groupId = localStorage.getItem(KEY_CURRENT_GRP);

    if (groupId !== null)
    {
      let group = this.store.select(getPractiseGroupsById(this.currentId, groupId));
      group.pipe(takeUntil(this.notifier)).subscribe({
        next: (value) => {
          if (value === undefined)
            return;

        this.currentGroupId = groupId;
        this.currentGroup = value;
        },
        error: (err) => console.log(err)          
      });
    }
  }

  formatDateObjAsString(date: Date) {
    let str = date.getFullYear().toString() + "-" + ((date.getMonth() + 1 < 10) ? "0" : "") + (date.getMonth() + 1).toString() + "-" +((date.getDate()  < 10) ? "0" : "") + (date.getDate().toString());
     return str;
  }
}
