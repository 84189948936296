import { createReducer, on } from "@ngrx/store";
import * as CommentsAction from "./comment.action";
import { Comment, CommentDetail } from "../models/Comment";
import { personsState } from "../../persons/state/persons.selector";

export const commentsFeatureKey = "comments";

export interface State {
    comments: Comment[]
}

export const initalState: State = {
    comments: []
}

export const reducer = createReducer(
    initalState,
    on(CommentsAction.loadCommentsSuccessAction, (state, {data}) : State => {
        return {
            comments: data
        };
    })
);