<div class="container">
    <h2>Aufgabe anlegen</h2>

    <form [formGroup]="taskForm" (ngSubmit)="createTask()" autocomplete="off">

        <app-input-field 
            inputId="title"
            label="Titel"
            placeholder="z.B. Affenschaukel"
            [control]="taskForm.controls['title']"
            errorMessage="Titel ist erforderlich"
            >
        </app-input-field>
        <app-input-field 
            inputId="series"
            label="Band"
            placeholder="z.B. 1"
            [control]="taskForm.controls['series']"
            errorMessage=""
            type="number"
            >
        </app-input-field>
        <app-input-field 
            inputId="chapter"
            label="Kapitel"
            placeholder="z.B. 1"
            [control]="taskForm.controls['chapter']"
            errorMessage=""
            type="number"
            >
        </app-input-field>
        <app-input-field 
            inputId="number"
            label="Nummer"
            placeholder="z.B. 1"
            [control]="taskForm.controls['number']"
            errorMessage=""
            type="number"
            >
        </app-input-field>
        
        <button type="submit" class="btn-primary" [disabled]="!taskForm.valid">Erstellen</button>
        <button type="button" class="btn-secondary" routerLink="/admin/tasks">Zurück</button>
    </form>
</div>
