import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { first, map } from 'rxjs';
import { loadPersonsAction } from 'src/app/modules/persons/state/persons.actions';
import { availableInstructors } from 'src/app/modules/persons/state/persons.selector';
import { editLessonAction, loadLessonByIdAction, loadLessonsAction } from '../../state/lesson.actions';
import { getLessonById } from '../../state/lesson.selector';
import { Location } from '@angular/common';
import { NavigationService } from 'src/app/modules/basic-controls/services/navigation.service';

@Component({
  selector: 'app-lesson-edit',
  templateUrl: './lesson-edit.component.html',
  styleUrls: ['./lesson-edit.component.scss']
})
export class LessonEditComponent {
  currentId = "";
  
  instructors$ = this.store.select(availableInstructors).pipe(map(ps => ps.map(p => {
    return {key: p.id, label: p.name + ", " + p.prename};
  })));
  
  lessonForm = this.fb.group({
    eventDate: ["", [Validators.required]],
    impulseHeldBy: ["", []],
    impulseTopic: ["", []]
  });

  constructor(private fb: FormBuilder, private activeRoute: ActivatedRoute, private store: Store, private location: Location, private navigationService: NavigationService) {
    this.currentId = this.activeRoute.snapshot.paramMap.get("id") as string;
    this.store.dispatch(loadLessonByIdAction({id: this.currentId}));
    this.store.dispatch(loadPersonsAction());
  }

  ngOnInit(): void {
    this.store.select(getLessonById(this.currentId))
    .pipe()
    .subscribe({
      next: (value) => {
        this.lessonForm.setValue({
          impulseHeldBy: value.impulseHeldBy.id,
          eventDate: this.getDateForInput(value.eventDate as string),
          impulseTopic: value.impulseTopic
        });
      }
    });
  }

  updateLesson() {
    let rawDate = new Date(Date.parse(this.lessonForm.value.eventDate || "1970-01-01"));
    let date: string = "";
    date += rawDate.getFullYear() + "-";
    date += (rawDate.getMonth() + 1) + "-";
    date += rawDate.getDate();

    this.store.dispatch(editLessonAction({
      id: this.currentId,
      data: {
        eventDate:  date,
        impulseHeldBy: this.lessonForm.value.impulseHeldBy || null,
        impulseTopic: this.lessonForm.value.impulseTopic || null,
      }
    }));
    this.back();
  }
  
  back() {
    this.navigationService.back("/lessons");
  }

  getDateForInput(sqlDate: string){

    let date = new Date(Date.parse(sqlDate));
    
    let day = date.getDate();
    let month = date.getMonth() + 1;

    let str = "";
    str += ((month < 10) ? "0" : "") + month.toString() + "-";
    str += ((day < 10) ? "0" : "") + day.toString();
    
    return date.getFullYear() + "-" + str;
  }
}
