<form [formGroup]="formular" (ngSubmit)="close(false)" autocomplete="off">
    <header>
        <h2>Gruppe auswählen</h2>
    </header>
    <app-drop-down 
            inputId="task"
            label="Gruppe"
            [entries$]="entries$"
            [control]="formular.controls['group']"
            errorMessage="Es muss eine Gruppe ausgewählt werden!"
            >
        </app-drop-down>
    <footer>
        <button 
            type="submit" 
            [disabled]="!formular.valid"  
            id="add-group" 
            class="btn-primary">
            Auswählen
        </button>
            <button 
                (click)="close(true)" 
                id="add-group" 
                type="button" 
                class="btn-secondary">
                Abbrechen
            </button>
    </footer>
</form>
