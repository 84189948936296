<div class="container">
    <h2 *ngIf="this.mode === 'INSTRUCTOR'">Jungbläser Anleiter anlegen</h2>
    <h2 *ngIf="this.mode === 'STUDENT'">Jungbläser anlegen</h2>
    <form [formGroup]="personForm" (ngSubmit)="createPerson()" autocomplete="off">
        <app-input-field 
            inputId="prename"
            label="Vorname"
            placeholder="z.B. Max"
            [control]="personForm.controls['prename']"
            errorMessage="Vorname ist erforderlich"
            >
        </app-input-field>
        <app-input-field 
            inputId="name"
            label="Nachname"
            placeholder="z.B. Musterman"
            [control]="personForm.controls['name']"
            errorMessage="Nachname ist erforderlich"
            >
        </app-input-field>
        <app-drop-down 
            inputId="instrument"
            label="Instrument"
            [entries$]="instruments$"
            [control]="personForm.controls['instrument']"
            errorMessage="Instrument ist erforderlich."
            >
        </app-drop-down>
        <app-drop-down 
            inputId="voice"
            label="Stimme"
            [entries$]="voices$"
            [control]="personForm.controls['voice']"
            errorMessage="Stimme ist erforderlich"
            >
        </app-drop-down>

        <button type="submit" class="btn-primary" [disabled]="!personForm.valid">Erstellen</button>
        <button *ngIf="this.mode === 'INSTRUCTOR'" type="button" class="btn-secondary" routerLink="/admin/instructors">Zurück</button>
        <button *ngIf="this.mode === 'STUDENT'" type="button" class="btn-secondary" routerLink="/admin/students">Zurück</button>
    </form>
</div>
