import { Component, Inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { AddTaskToLessonComponent } from '../add-task-to-lesson/add-task-to-lesson.component';

@Component({
  selector: 'app-add-practise-group',
  templateUrl: './add-practise-group.component.html',
  styleUrls: ['./add-practise-group.component.scss']
})
export class AddPractiseGroupComponent {


  addPractiseGroupForm = this.fb.group({
    name: ["", [Validators.required]]
  });
  
  constructor(public dialogRef : MatDialogRef<AddPractiseGroupComponent>, private fb: FormBuilder, private store: Store) {

  }

  close(add: boolean) {
    if (!add){
      this.dialogRef.close();
    } else {
      this.dialogRef.close(this.addPractiseGroupForm.value.name);
    }
  }
}
