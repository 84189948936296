<header>
    <div class="brand">
        <h1>Jungbläser</h1>
    </div>
    <nav *ngIf="userLoggedIn$ | async">
        <ul>
            <li routerLinkActive="active" routerLink="/home">
                <a>Home</a>
            </li>
            <li routerLinkActive="active" routerLink="/lessons">
                <a>Übungsstunden</a>
            </li>
            <!-- <li routerLinkActive="active" routerLink="/persons">
                <a>Personen</a>
            </li> -->
            <li class="profile" routerLink="/profile" routerLinkActive="active">
                <i class="fa-solid fa-user"></i>
            </li>
            <li *ngIf="this.adminUserLoggedIn$ | async" class="btn-icon-only" routerLink="/admin" routerLinkActive="active">
                <i class="fa-solid fa-screwdriver-wrench"></i>
            </li>
            <li *ngIf="this.adminUserLoggedIn$ | async" class="btn-icon-only" (click)="logout()">
                <i class="fa-solid fa-right-from-bracket"></i>
            </li>
        </ul>
    </nav>
    <div *ngIf="userLoggedIn$ | async" class="menu" (click)="toggleMenu()">
        <i *ngIf="menuActive" class="fa-solid fa-xmark"></i>
        <i *ngIf="!menuActive" class="fa-solid fa-bars"></i>
    </div>
</header>
<nav  class="menu-dropdown" *ngIf="menuActive">
    <ul>
        <li routerLinkActive="active" routerLink="/home" (click)="toggleMenu()">
            <a>Home</a>
        </li>
        <li routerLinkActive="active" routerLink="/lessons" (click)="toggleMenu()">
            <a>Übungsstunden</a>
        </li>
        <!-- <li routerLinkActive="active" routerLink="/persons" (click)="toggleMenu()">
            <a>Personen</a>
        </li> -->
        <li routerLinkActive="active" class="profile" routerLink="/profile" (click)="toggleMenu()">
            <a> Mein Profil</a>
        </li>
        <li *ngIf="this.adminUserLoggedIn$ | async" routerLinkActive="active" class="users" routerLink="/admin" (click)="toggleMenu()">
            <a>Administrierung</a>
        </li>
        <li class="users" (click)="logout()">
            <a>Ausloggen</a>
        </li>
    </ul>
</nav>