<div class="container">
    <h2>Mein Profil</h2>
    <div class="property">
        <label for="prename">Vorname:</label>
        <p name="prename">{{(user$ | async)?.prename}}</p>
    </div>
    <div class="property">
        <label for="name">Nachname:</label>
        <p name="name">{{(user$ | async)?.name}}</p>
    </div>
    <div class="property">
        <label for="email">Email Adresse:</label>
        <p name="email">{{(user$ | async)?.email}}</p>
    </div>
    <div class="property">
        <label for="choir">Posaunenchor:</label>
        <p name="choir">{{(user$ | async)?.choir?.name}}</p>
    </div>
    <div class="property">
        <label for="rights">Rechte:</label>
        <ul name="rights">
            <li *ngFor="let right of (user$ | async)?.rights">
                <p>{{right.name}}</p>
            </li>
        </ul>
    </div>
</div>

