import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import * as LessonsActions from "./lesson.actions";
import { catchError, first, map, mergeMap, of, pipe, tap } from "rxjs";
import { LessonService } from "../services/lesson.service";
import { Store } from "@ngrx/store";
import { allLessons } from "./lesson.selector";

@Injectable()
export class LessonsEffects {

    requestLessonsEffect = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(LessonsActions.loadLessonsAction), 
                mergeMap(() => this.lessonService.loadAllLessons().pipe(
                    map(lessons => LessonsActions.loadLessonsSuccessAction({data: lessons})),
                    catchError(error => of(LessonsActions.loadLessonsFailAction({ error: error })))
                ))
            );
        }
    );

    loadLessonByIdEffect = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(LessonsActions.loadLessonByIdAction), 
                mergeMap((data) => this.lessonService.loadSingleLessonWithDetails(data.id).pipe(
                    map(lesson => LessonsActions.loadLessonByIdSuccessAction({data: lesson})),
                    catchError(error => of(LessonsActions.loadLessonByIdFailAction({ error: error })))
                ))
            );
        }
    );

    loadLastLessonBeforeCurrentEffect = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(LessonsActions.loadLastLessonBeforeCurrent),
                tap( data => {
                    this.store.select(allLessons).pipe(first()).subscribe(
                        lessons => {
                            let idx = lessons.findIndex(l => l.id == data.id);
                            if (idx === -1)
                                return;

                            this.store.dispatch(LessonsActions.loadLessonByIdAction({id: lessons[idx + 1].id}));
                        },
                        err => console.log("error")
                    );  
                })
            );
        },
        {
            dispatch: false
        }

    );

    addLessonsEffect = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(LessonsActions.addLessonAction), 
                tap( data => this.lessonService.addLesson(data.data))
            );
        },
        {
            dispatch: false
        }
    );

    editLessonsEffect = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(LessonsActions.editLessonAction), 
                tap( data => this.lessonService.updateLesson(data.id, data.data))
            );
        },
        {
            dispatch: false
        }
    );

    addTaskToLessonEffect = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(LessonsActions.addTaskToLessonAction), 
                tap( data => this.lessonService.addTaskToLesson(data.lId, data.tId))
            );
        },
        {
            dispatch: false
        }
    );

    removeTaskFromLessonEffect = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(LessonsActions.removeTaskFromLessonAction), 
                tap( data => this.lessonService.removeTaskFromLesson(data.lId, data.tId))
            );
        },
        {
            dispatch: false
        }
    );

    addPractiseGroupEffect = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(LessonsActions.addPractiseGroupLessonAction), 
                tap( data => this.lessonService.addPractiseGroup(data.data))
            );
        },
        {
            dispatch: false
        }
    );

    removePractiseGroupEffect = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(LessonsActions.removePractiseGroupLessonAction), 
                tap( data => this.lessonService.removePractiseGroup(data.id, data.lId))
            );
        },
        {
            dispatch: false
        }
    );

    

    addParticipantToGroupEffect = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(LessonsActions.addParticipantToGroupAction), 
                tap( data => this.lessonService.addPartcipantToGroup(data.pId, data.gId, data.lId))
            );
        },
        {
            dispatch: false
        }
    );

    removeParticipantFromGroupEffect = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(LessonsActions.removeParticipantFromGroupAction), 
                tap( data => this.lessonService.removeParticipantFromGroup(data.pId, data.gId, data.lId))
            );
        },
        {
            dispatch: false
        }
    );

    addInstructorToGroupEffect = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(LessonsActions.addInstructorToGroupAction), 
                tap( data => this.lessonService.addInstructorToGroup(data.pId, data.gId, data.lId))
            );
        },
        {
            dispatch: false
        }
    );

    removeInstructorFromGroupEffect = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(LessonsActions.removeInstructorFromGroupAction), 
                tap( data => this.lessonService.removeInstructorFromGroup(data.pId, data.gId, data.lId))
            );
        },
        {
            dispatch: false
        }
    );

    updateParticipationForLessonForPersonEffect = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(LessonsActions.updateParticipationLessonPersonAction), 
                tap( data => this.lessonService.updateParticipationForLessonForPerson(data.pId, data.lId, data.data))
            );
        },
        {
            dispatch: false
        }
    );

    loadParticipationDataForLessonActionEffect = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(LessonsActions.loadParticipationDataForLessonAction), 
                tap( data => this.lessonService.loadParticipationData(data.lId))
            );
        },
        {
            dispatch: false
        }
    );

    constructor(private actions$: Actions, private store: Store, private lessonService : LessonService){

    }
}