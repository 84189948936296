import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RegistrationComponent } from './views/registration/registration.component';
import { LoginComponent } from './views/login/login.component';
import { ProfilePageComponent } from './views/profile-page/profile-page.component';
import { BasicGuard } from './guards/basic.guard';
import { AdminPageComponent } from './views/admin-page/admin-page.component';
import { AdminGuard } from './guards/admin.guard';

const routes: Routes = [
  {
    path: "registration",
    component: RegistrationComponent
  },
  {
    path: "login",
    component: LoginComponent
  },
  {
    path: "profile",
    component: ProfilePageComponent,
    canActivate: [BasicGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthenticationRoutingModule { }
