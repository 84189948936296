import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-input-field',
  templateUrl: './input-field.component.html',
  styleUrls: ['./input-field.component.scss']
})
export class InputFieldComponent {
  @Input() inputId: string = "";
  @Input() control = new FormControl();
  @Input() label = "";
  @Input() placeholder = "";
  @Input() errorMessage = "";
  @Input() type = "text";
  @Input() required = true;
}
