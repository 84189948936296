import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CommentsRoutingModule } from './comments-routing.module';
import { StoreModule } from '@ngrx/store';
import { commentsFeatureKey, reducer } from './state/comment.reducer';
import { CommentsEffects} from './state/comment.effects';
import { EffectsModule } from '@ngrx/effects';
import { CommentOverlayComponent } from './components/comment-overlay/comment-overlay.component';
import { BasicControlsModule } from '../basic-controls/basic-controls.module';
import { ReactiveFormsModule } from '@angular/forms';


@NgModule({
  declarations: [
    CommentOverlayComponent
  ],
  imports: [
    CommonModule,
    CommentsRoutingModule,
    BasicControlsModule,
    ReactiveFormsModule,
    StoreModule.forFeature(commentsFeatureKey, reducer),
    EffectsModule.forFeature([
      CommentsEffects
    ])
  ]
})
export class CommentsModule { }
