import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Lesson } from '../../models/Lesson';
import { getLessonById } from '../../state/lesson.selector';
import { Store } from '@ngrx/store';
import { Observable, first } from 'rxjs';
import { Person } from 'src/app/modules/persons/models/Person';
import { ParticipationStateType } from '../../models/ParticipationStateType';
import { Participation } from '../../models/Participation';
import { availablePersons } from 'src/app/modules/persons/state/persons.selector';
import { TaskRaw } from 'src/app/modules/task-handling/models/TaskRaw';
import { PractiseGroup } from '../../models/PractiseGroup';
import { loadPersonsAction } from 'src/app/modules/persons/state/persons.actions';
import { loadLastLessonBeforeCurrent, loadLessonByIdAction, loadLessonsAction } from '../../state/lesson.actions';
import { group } from '@angular/animations';
import { environment } from 'src/environments/environment';
import { LessonService } from '../../services/lesson.service';

@Component({
  selector: 'app-lesson-details',
  templateUrl: './lesson-details.component.html',
  styleUrls: ['./lesson-details.component.scss']
})
export class LessonDetailsComponent {

  currentId: string = "";
  currentLesson: Lesson | undefined;
  absentPerson: Participation[] = [];
  persons: Person[] = [];
  dailyTasks: TaskRaw[] = [];
  groups: PractiseGroup[] = [];

  constructor(private store: Store, private activeRoute: ActivatedRoute, private service: LessonService) {
    this.currentId = this.activeRoute.snapshot.paramMap.get("id") as string;
    this.store.dispatch(loadLessonByIdAction({id: this.currentId}));
    this.store.dispatch(loadPersonsAction());
    this.store.select(getLessonById(this.currentId))
    .pipe()
    .subscribe({
      next: (value) => {
        this.currentLesson = value;
        this.absentPerson = value.participation.filter(p => p.state !== ParticipationStateType.PRESENT);
        this.dailyTasks = value.tasks;
        this.groups = value.groups;
      }
    });
    this.store.dispatch(loadLastLessonBeforeCurrent({id: this.currentId}));

    this.store.select(availablePersons).pipe()
    .subscribe({
      next: (value) => {
        this.persons = value;
      }
    });
  }

  getPersonNameById(id: string): string {
    let person = this.persons.find(p => p.id === id);
    
    return person?.name + ", " + person?.prename;
  }

  formatDate(dateString: any) {
    let date = new Date(Date.parse(dateString));

    let day = date.getDate();
    let month = date.getMonth() + 1;

    let str = "";
    str += ((day < 10) ? "0" : "") + day.toString() + ".";
    str += ((month < 10) ? "0" : "") + month.toString() + ".";

    return str + date.getFullYear();
  }

  startDownload(groupId: string) {
    this.service.donwloadGroupReport(groupId);
    
  }

  ngOnInit(): void {
    
  }
}
