import { createFeatureSelector, createSelector, select } from "@ngrx/store";
import * as fromTaskHandling from "./task-handling.reducer";
import { Task } from "../models/Task";
import { TaskRaw } from "../models/TaskRaw";
import { TaskProgress } from "../models/TaskProgress";

export const taskHandlingState = createFeatureSelector<fromTaskHandling.State>(
    fromTaskHandling.tasksFeatureKey
);

export const generalTaskProgress = createSelector(
    taskHandlingState,
    (tasks: fromTaskHandling.State) : Task[] => {
        return tasks.tasks;
    }
);

export const allTasksInRawFormat = createSelector(
    taskHandlingState,
    (tasks: fromTaskHandling.State) : TaskRaw[] => {
        return tasks.tasks.map(t => {
            return {
                id: t.id,
                title: t.title,
                series: t.series,
                chapter: t.chapter,
                number: t.number,
                isActive: t.isActive
            }
        });
    }
);

export const allOpenTasks = createSelector(
    taskHandlingState,
    (tasks: fromTaskHandling.State) : Task[] => {
        return tasks.openTasks;
    }
);

export const topOpenTasks = createSelector(
    taskHandlingState,
    (tasks: fromTaskHandling.State) : Task[] => {

        return tasks.openTasks.slice(0, tasks.openTasks.length <= 30 ? tasks.openTasks.length : 30);
    }
);

export const getTaskProgressById = (id: string) => createSelector(
    taskHandlingState,
    (tasks: fromTaskHandling.State) : Task => {
        return tasks.tasks.find(t => t.id === id) || new Task();
    }
);

export const getTaskProgressForParticipant = (tId: string, pId: string) => createSelector(
    taskHandlingState,
    (tasks: fromTaskHandling.State)  => {
        let task = tasks.tasks.find(t => t.id == tId);

        if (task === undefined || task === null)
            return null;
        return task.progress.find(prog => prog.personId === pId);
    }
);

