<div class="container">
    <h2>Aufgaben</h2>
    <div class="toolbar">
        <button type="button" class="btn-primary btn-add" routerLink="/admin/tasks/add"><i class="fa-solid fa-plus"></i>Hinzufügen</button>
    </div>
    <div class="data">
        <div class="data-header">
            <h3 class="col">
                Aufgabe
            </h3>
            <h3></h3>
        </div>
        <div *ngFor="let task of this.tasksRaw$ | async" class="data-entry">
            <div class="col field-data">
                <p>{{task.title}}</p>
                <p *ngIf="task.series !== null && task.chapter !== null && task.number !== null">Band {{task.series}} - Kapitel {{task.chapter}} - Nr. {{task.number}}</p>
            </div>
            <div class="col action">
                <button type="button" class="btn-secondary btn-add" [routerLink]="['/admin/tasks/edit', task.id]">
                    <i class="fa-solid fa-pen"></i>
                </button>
                <button type="button" class="btn-primary btn-add" (click)="deleteTask(task.id)">
                    <i class="fa-solid fa-trash"></i>
                </button>
            </div>
        </div>
    </div>
</div>
