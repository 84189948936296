<h2>Login</h2>

<form [formGroup]="loginForm" (ngSubmit)="login()" autocomplete="on">
    <div class="input-group">
        <label for="email">* Email-Adresse</label>
        <input id="email" type="email" class="text-input" [class.error]="loginForm.controls['email'].touched && !loginForm.controls['email'].valid" formControlName="email">
    </div>
    <div class="input-group">
        <label for="password">* Passwort</label>
        <input id="password" type="password" class="text-input" [class.error]="loginForm.controls['password'].touched && !loginForm.controls['password'].valid" formControlName="password" >
    </div>
    <button type="submit" class="btn-primary" [disabled]="!loginForm.valid">Einloggen</button>
    <button type="button" class="btn-secondary" routerLink="/registration">Registrierung</button>
</form>